import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Paiements_Class from '../../class/Paiements_Class'

import Moment from 'react-moment'
import 'moment/locale/fr'

function TransactionsVente() {

    const navigate = useNavigate();

    const context = useContext(Context)

    const [transactions, setTransactions] = useState(false)

    const getTransactions = async (type = 1) => {

        let results = await Paiements_Class.transactions(type)

        console.log(results)
        
        if(results.success){

            setTransactions(results.data)

        }else{

            setTransactions(results.message)

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        getTransactions(2)

        document.title = "Transactions - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">

                    <a href="" className='cl2 textUnd fz09'>Profil</a>
                    
                    <HeaderProfile />

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        <ProfileMenu data="transactions"/>

                        <div className="transactions pb20">

                            <div className="flex jc-start mb20">
                                <Link to={"/mon-profile/transactions"} className='cl2 mr10'>Achats</Link>
                                <Link to={"/mon-profile/transactions/ventes"} className="textUnd mr10 cl2">Ventes</Link>
                            </div>

                            {

                                transactions

                                ?

                                    typeof transactions === "string"

                                    ?

                                    <p className='fz09'>{ transactions }</p>

                                    :

                                    <>
                                        
                                        {
                                        
                                        transactions.map(transaction => 

                                            <Link to={"/mon-profile/discussion/" + transaction.discussion} className="trans bg6 cl2" key={ transaction.idT }>
                                        
                                                <div className='infos'>

                                                    <div className="av medium">
                                                        <img src={ Func.avatar(transaction.avatar) }/>
                                                    </div>

                                                    <span>{ transaction.name }</span>
                                                    
                                                    <span className='t cl3'>{ parseFloat(transaction.prix).toFixed(2) } €</span>

                                                    { transaction.stt == 2 && <span className='t cl2'>Transaction annulée</span>}
                                                    { transaction.stt == 3 && <span className='t cl2'>Transaction annulée</span>}

                                                </div>

                                                <span className='date'><Moment locale="fr" format='DD MMMM Y'>{ transaction.date }</Moment></span>

                                            </Link>

                                        )
                                        
                                        }

                                    </>

                                :

                                <div className='flex jc-center mt50 mb50'>
                                    <div className="lds-dual-ring col"></div>
                                </div>

                            }

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default TransactionsVente