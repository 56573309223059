import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import Annonces from '../../class/Annonces'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

function Favoris() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [articles, setArticles] = useState(false)
    
    const getArticles = async () => {

        let results = await Annonces.favoris()

        setArticles(results.data)

    }

    useEffect(() => {

        Func.session(context, navigate)

        getArticles(setArticles)

        document.title = "Mes articles favoris - " + APP_NAME

        window.scrollTo(0, 0)

        document.body.classList.remove('mn')

    }, [])


    return (
        <div>

            <Header />

            <div>

            <HeaderBox />

                <div className="c1 content">

                    <div className="c pt80 pb40">

                        <h2 className='ta-center mb30'>Mes favoris</h2>

                            {

                                articles

                                ?

                                    <div className="grid4 articles m_p0">

                                        { articles.map(element => <Article data={element} key={element.idA} />)}
                                        
                                    </div>

                                :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Favoris