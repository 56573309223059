// Dev

// const WEBROOT = "http://localhost:3000/"
// const API_ROOT = "http://127.0.0.1/Sisters_Green/api/"

// const PHOTOS_ROOT = "http://127.0.0.1/Sisters_Green/image/annonces/"

// const AVATAR_ROOT = "http://127.0.0.1/Sisters_Green/image/profile/"

// Prod

const WEBROOT = "https://www.sistersgreen.fr/"
const API_ROOT = WEBROOT + "api/"

const PHOTOS_ROOT = WEBROOT + "image/annonces/"

const AVATAR_ROOT = WEBROOT + "image/profile/"

const AVATAR_DEFAULT = "/image/avatar.png"

const APP_NAME = "Sisters Green"

// API

const API_SECRET = "123123"

export { WEBROOT, API_ROOT, APP_NAME, API_SECRET, AVATAR_ROOT, AVATAR_DEFAULT, PHOTOS_ROOT }