import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { WEBROOT, APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import  User from '../class/User'

import  { Text } from '../constants/Text'

import  Func from '../class/Func'


const Register = async (e,setError, navigate, setSession) => {

    e.preventDefault()

    let inputs = e.target

    let name = inputs.name

    let email = inputs.email

    let pass = inputs.pass

    if(name.value !== "" && email.value !== "" && pass.value !== "" && inputs.checkbox_conditions.checked){

     const results = await User.register(inputs);

     if(results.success){
       
        Func.saveSession(results.data, results.likes, setSession)

        Func.setFlash(results.message)

        navigate('/mon-profile/parametres')

     }else{

        setError(results.message)

     }

       
    }else{
      
        setError('Assurez-vous que tout les champs sont remplis')

    }
   
}


function Inscription() {

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [password, setPassword] = useState('password')

    const navigate = useNavigate()

    const context = useContext(Context)

    useEffect(() => {

        document.title = "Inscription - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />

            <div className="register content">

                <HeaderBox />

                <div className='pos-r'>

                    <div className="col-3 m0a pt80 pb80 ta-center m_p20">

                        <h1 className='mb30 fw4'>Inscris-toi avec ton email</h1>

                        {(error !== '') && <p className="error">{error}</p>}                    

                        <form action="" method="POST" className="gla-form" onSubmit={(e) => Register(e,setError, navigate, context.setSession)}>

                            <input type="text" name="name" placeholder="Nom utilisateur / pseudo" required="true" />

                            <input type="text" name="email" placeholder="E-mail" required="true" />

                            <div className='input_eye'>

                                <input type={password} name="pass" placeholder="Mot de passe" required="true" />
                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" onClick={e => password == 'password' ? setPassword('text') :  setPassword('password') }><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>

                            </div>

                            <div className='flex jc-start ai-start mb10 m_flex'>
                                <input type="checkbox" name='checkbox_conditions' required={true}/>
                                <div className='fz09 ml10 ta-start'>Je confirme avoir pris connaissance des <Link to={'/page/termes-et-conditions'} className="cl3 fw5">Conditions Générales d'Utilisation</Link> du Site Sisters Green et les accepte.</div>
                            </div>

                            {/* <div className='flex jc-start ai-start'>
                                <input type="checkbox" name='checkbox_politique' required={true}/>
                                <div className='fz09 ml10 ta-start'>Je souhaite recevoir par email les offres personnalisées et les dernières mises à jour de Sisters Green.</div>
                            </div> */}

                            <input type="submit" name="log" value="S'inscrire" className="btn bg4 cl1 brc1solid brc4" />


                        </form>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )

}

export default Inscription