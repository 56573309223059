import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Annonces from '../../class/Annonces';
import User from '../../class/User';

function SupprimerCompte() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [success, setSuccess] = useState(false)
    
    const action = async () => {

        let results = await User.deleteAccount()

        if (results.success){

            setSuccess(results.message)

        }else{

            navigate('/404')

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Supprimer mon compte - " + APP_NAME

        window.scrollTo(0, 0)
        
    }, [])


    return (

        <div>
            
            <Header />

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c br8 p40 mt20 ta-center">

                    <h1 className='cl2 mb20'>Supprimer mon compte</h1>

                    {(success !== false) && <p className="succes">{success}</p>}

                    {

                        success == false

                        &&

                        <>

                            <p className='mb20'>Tu veux supprimer définitivement ton compte ?</p>

                            <span className='btn bg4 cl1 mr10' onClick={() => action()}>Oui</span>
                            <span className='btn bg4 cl1' onClick={ () => navigate(-1)}>Non</span>

                        </>
                        
                    }

                </div>

            </div>

            <Footer/>


        </div>   
    )

}

export default SupprimerCompte