import React, { useState, useContext, useEffect } from 'react';

import { Context } from '../class/Context'

import { useNavigate, Link } from "react-router-dom";

import { WEBROOT, APP_NAME, PHOTOS_ROOT } from '../constants/general';

import  Func  from '../class/Func'

import Annonces from '../class/Annonces'
import User from '../class/User';

const Header = () => {

    const context = useContext(Context)

    const navigate = useNavigate()

    const [search, setSearch] = useState({})

    const [search_box, setSearchBox] = useState(false)

    const [badge, setBadge] = useState(false)

    const debounce = (func, timeout = 400) => {
        let timer;
        return (...args) => {
            clearTimeout(timer)
            timer = setTimeout(() => { func.apply(this, args); }, timeout)
        }
    }
    
    const searchFunc = async (e) => {

        e.preventDefault()

        let query = e.target.value
        
        let results = await Annonces.search(query)

        if(results.success){

            if(results.data.length > 0){
                setSearch(results.data)
            }else{
                setSearch({})
            }

        }

    }
    
    const processChange = debounce((e) => searchFunc(e))

    const messages = async () => {
        
        let results = await User.messages()

        if(results.success) if(results.message) setBadge(true)

    }

    useEffect(() => {

        if(context.session) messages()
    
      }, [])

    return (
        <header>

        <div className="ps-r flex c-0 ai-center pos-r m_flex m_col_10">

            <div className="col-2 ai-center head m_col_2">
                <Link to={'/'} className="logo-link"><img src={ WEBROOT + "image/sisters_green_logo.png" } alt={APP_NAME} className="logo mr20" /></Link>
            </div>

            <div className="col-8 m_flex flex-end ai-center __menu">
                
                <nav>
                    <ul>
                        <li>
                            <Link to={'/vendre'} className="btn brc3 brc2solid cl1 col-10 bg3 pc_d_none ta-center">Vendre</Link>
                            { !context.session && <Link to={'/connexion'} className="btn brc3 brc2solid col-10 cl2 pc_d_none bgt mr10 ta-center mb30">S'inscrire / se connecter</Link> }
                        </li>

                        <li><Link to={'/categorie/plantes'}>Pépinière</Link></li>
                        <li><Link to={'/page/plant-sitting'}>Plant-sitting</Link></li>
                        <li><Link to={'/page/boite-a-outils'}>Boite à outils</Link></li>
                        <li className='pc_d_none'><Link to={'/page/la-chartegreen'}>Charte Green</Link></li>

                        {

                            context.session

                            &&

                            <div><p className='btn cl3 fw5 pc_d_none p20' onClick={() => Func.logout(context, navigate)}>Se déconnecter</p></div>
                                
                        }

                    </ul>
                </nav>
                
                {

                    context.session

                    ?

                        <div>
                            <span className="icon fz15 cur_p m_d_none" onClick={ e => setSearchBox(!search_box) }>d</span>
                            <div className='notif_badge'>
                                <Link to={'/mon-profile/discussions'} className='icon fz15 ml20 cl2'>c</Link>
                                {badge && <span className='badge bg3'></span>}
                            </div>
                            <Link to={'/mon-profile/favoris'} className='icon fz15 ml20 cl2'>j</Link>
                            <Link to={'/mon-profile/articles'} className='icon fz15 mr20 ml20 cl2'>b</Link>
                            <span href={'#'} className="menu-res icon fz15 cl2 pc_d_none menu_open_on" onClick={ e => Func.openMenu(e) }>1</span>
                            <span href={'#'} className="menu-res icon fz15 cl2 pc_d_none menu_open_off" onClick={ e => Func.openMenu(e) }>w</span>
                            <p className='btn brc3 brc2solid cl1 d-in-block cl3 mr10 m_d_none' onClick={() => Func.logout(context, navigate)}>Déconnexion</p>
                        </div>

                    :

                        <>
                            <span className="icon fz13 mr20 ml20 cur_p m_d_none" onClick={ e => setSearchBox(!search_box) }>d</span>
                            <span href={'#'} className="menu-res icon fz15 cl2 pc_d_none menu_open_on" onClick={ e => Func.openMenu(e) }>1</span>
                            <span href={'#'} className="menu-res icon fz15 cl2 pc_d_none menu_open_off" onClick={ e => Func.openMenu(e) }>w</span>
                            <Link to={'/connexion'} className="btn brc3 brc2solid cl2 d-in-block bgt mr10 m_d_none">S'inscrire / se connecter</Link>
                        </>
                        
                }
                
                <Link to={'/vendre'} className="btn brc3 brc2solid cl1 d-in-block bg3 m_d_none">Vendre</Link>

            </div>
            
            {

                search_box

                &&

                <form action="/search" method='GET' className='search_box m_d_none' >
                
                    <div className='mb5'>
                        <span className="icon fz15 cur_p">d</span>
                        <input name='query' placeholder='Rechercher ...' onChange={ e => processChange(e) } autoComplete="off"/>
                    </div>
                    
                        {

                            search.length > 0

                            &&

                            <div>

                                { search.map(s => <Link to={'/annonce/' + s.id} key={ s.id }>{ s.titre }</Link>) }

                            </div>

                        }

                </form>

            }

        </div>

        <form action="/search" method='GET' className='search_box pc_d_none m_d_none m_search_box' >
                
            <div className='mb5'>
                <span className="icon fz15 cur_p">d</span>
                <input type='search' name='query' placeholder='Rechercher ...' onChange={ e => processChange(e) } autoComplete="off"/>
            </div>
            
                {

                    search.length > 0

                    &&

                    <div>

                        { search.map(s => <Link to={'/annonce/' + s.id} key={ s.id }>{ s.titre }</Link>) }

                    </div>

                }

        </form>

    </header>
    );

}

const Footer = () => {

    return (
        <footer className="bg4 p30">

            <div className="c fz09">

                <div className='grid3 mb30'>

                    <div>
                        <Link to={'/page/a-propos-des-sisters-green'} className='cl5 mr10 fw4'>À propos des Sisters Green</Link>
                        <Link to={'/page/eco-responsabilite'} className='cl5 mr10 fw4'>🌿 Notre engagement</Link>
                        <Link to={'/page/faq'} className='cl5 mr10 fw4'>FAQ Sisters Green</Link>
                        <Link to={'/page/faq-colissimo-et-courrier'} className='cl5 mr10 fw4'>FAQ Colissimo et courrier</Link>
                    </div>

                    <div>
                        <Link to={'/page/termes-et-conditions'} className='cl5 mr10 fw4 m_mt_0'>Termes et Conditions</Link>
                        <Link to={'/page/mentions-legales'} className='cl5 mr10 fw4'>Mentions légales</Link>
                        <Link to={'/page/politique-des-cookies'} className='cl5 mr10 fw4'>Politique de Cookies</Link>
                    </div>

                    <div className="social">

                        <div className='flex'>
                            <Link to={'/page/contact'}>
                                <span className='cl5 mr10'>contact</span>
                                <span className='cl5 icon mr20'>e</span>
                            </Link>

                            <Link to={'http://m.me/111112805111587'} className='cl5 mr10' target='_blank'>SAV Messenger</Link>
                        </div>

                        <Link to={'https://www.instagram.com/explore/tags/sistersgreenofficiel/?hl=fr'} target="_blank" className='mr5 cl5'>#sistersgreenofficiel</Link>

                        <div className='flex ai-center jc-end'>
                            <span className='cl5 mr15 mt10'>suivez-nous</span>
                            <Link to={'https://www.facebook.com/profile.php?id=100086345094727'} target="_blank" className="icon cl5 mr10">g</Link>
                            <Link to={'https://www.instagram.com/sisters_green_officiel'} target="_blank" className="icon cl5">h</Link>
                        </div>

                    </div>

                </div>

                <span className='cl2 mt40 fz08'>&copy; 2023 Sisters Green - Développé par <Link to={'https://www.globalads.dz'} className='cl2' target='_blank' title='Agence de développement de site web'>Global Ads</Link></span>

            </div>

            {/* <div className='cookie_box'>

                <p>"Sistersgreen utilise des cookies pour améliorer son site web. Certains sont nécessaires au bon fonctionnement du site et des services, tandis que d'autres sont facultatifs et destinés à améliorer ou personnaliser votre expérience. Si vous souhaitez en savoir plus, veuillez lire notre <Link to={'/'}>Politique de cookies</Link> et <Link to={'/'}>politique de confidentialite</Link>. Vous pouvez modifier vos paramètres à tout moment dans vos paramètres de confidentialité."</p>

                <div className='flex jc-end mt20'>

                    <span className='btn bg4 cl1'>Accepter</span>

                </div>

            </div> */}

        </footer>
    );

}

const HeaderBox = () => {

    return (
        <div className="bg4 topbar">
            <p className='col-4 cl5 m0a'>La charte Green est un engagement, <u><Link to={"/page/la-chartegreen"} className="cl1">je consulte la charte</Link></u></p>
        </div>
    );

}

const Article = (props) => {

    const context = useContext(Context)

    const navigate = useNavigate()

    let likes = []

    if(localStorage.likes !== undefined) likes = localStorage.likes

    const favoris = async (e, id) => {

        e.preventDefault()

        e.stopPropagation()

        if(!context.session){

            navigate('/connexion')

            return

        }

        let t = e.target

        t.classList.remove('cl4')
        t.classList.add('cl2')

        let results = await Annonces.ajoutFavoris(id)

        if(results.success){

            localStorage.likes = results.likes

            if(results.action == 'add'){

                t.innerText = 'k'
                t.classList.remove('cl2')
                t.classList.add('cl4')

            }else{

                t.innerText = 'j'

                t.classList.remove('cl4')
                t.classList.add('cl2')

            }

        }else{

            alert(results.message)

        }

    }

    const id = props.data.idA

    return (

        <Link to={'/annonce/' + id} className="article bg6 cl2">
            <div className="img">
                <img src={ PHOTOS_ROOT + "small/" + props.data.name + ".jpg"} alt={props.data.titre} />
            </div>
            <div className="cnt">
                <div className="flex m_flex">
                    <span className='price'>{props.data.prix}€</span>

                    {

                        localStorage.idU !== null && localStorage.idU == props.data.user_id

                        ?

                            <></>

                        :

                            likes.includes(id)

                            ?

                                <span className='icon cl4' onClick={e => favoris(e, id)}>k</span>

                            :

                                <span className='icon cl2' onClick={e => favoris(e, id)}>j</span>

                    }

                </div>
                {
                    props.data.taille == 'Gr'

                    ?

                    <span className='h bg3 opt icon fz08'>x</span>

                    :

                    <span className='h bg3 opt'>{props.data.taille}</span>

                }
                <span className="t h">{props.data.titre}</span>
            </div>
        </Link>
    )

}

export { Header, Footer, HeaderBox, Article };