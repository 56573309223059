import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../components/imports'

import { APP_NAME, PHOTOS_ROOT } from '../constants/general'

import { Context } from '../class/Context'

import Annonces from '../class/Annonces'

import Func from '../class/Func'

function Lot() {

    let { id } = useParams()

    const context = useContext(Context)

    const navigate = useNavigate()

    const [error, setError] = useState('')

    const [price, setPrice] = useState(0)

    const [articles, setArticles] = useState([])

    const [likes, setLikes] = useState([])

    const [annoncesUser, setAnnoncesUser] = useState(false)

    const getAnnoncesUser = async () => {

        let results = await Annonces.annoncesByUser(id, {limit: 200})
    
        if(results.success){
            
            setAnnoncesUser(results.data)

        }else{

            navigate('/404')

        }
    
    }

    const commander = async () => {

        let articles_id = ''

        if(articles.length < 2){

            setError('Tu dois sélectionner au minimum deux articles pour créer un lot.')

            return

        }

        articles.map(elem => articles_id += elem + ',')

        articles_id = articles_id.substring(0, articles_id.length-1)

        navigate('/commanderLot?&lot=' + articles_id)
       
    }

    const addArticle = (e, id, prix) => {

        setError('')

        let element = e.target

        let article = articles

        if(article.includes(id)){

            let index = article.indexOf(id)

            if(index !== -1){
                article.splice(index, 1)
            }

            setPrice((parseFloat(price) - parseFloat(prix)).toFixed(2))

            element.innerHTML = 'Ajouter'

            element.classList.remove('cl4')

            element.classList.add('bg4')
            element.classList.add('cl1')

        }else{

            article.push(id)

            setPrice((parseFloat(price) + parseFloat(prix)).toFixed(2))

            element.innerHTML = 'Supprimer'

            element.classList.remove('bg4')
            element.classList.remove('cl5')

            element.classList.add('cl4')

        }
        
        setArticles(article)

    }

    const favoris = async (e, id) => {

        e.preventDefault()

        e.stopPropagation()

        if(!context.session){

            Func.setFlash("Connectez vous pour ajouter des annonces à vos favoris", "error")

            navigate('/connexion')

            return

        }

        let t = e.target

        t.classList.remove('cl4')
        t.classList.add('cl2')

        let results = await Annonces.ajoutFavoris(id)

        if(results.success){

            localStorage.likes = results.likes

            if(results.action == 'add'){

                t.innerText = 'k'
                t.classList.remove('cl2')
                t.classList.add('cl4')

            }else{

                t.innerText = 'j'

                t.classList.remove('cl4')
                t.classList.add('cl2')

            }

        }else{

            alert(results.message)

        }

    }

    useEffect(() => {

        getAnnoncesUser()

        if(localStorage.likes !== undefined) setLikes(localStorage.likes)
        
        document.title = "Acheter un lot - " + APP_NAME


    }, [id])


    return (

        <div>

            <Header />

            <div className="categories">

                <HeaderBox />

                <div className="single">

                    <div className='content'>

                        <div className="c">

                            <a href="" className='cl2 textUnd fz09'>Acheter en lot</a>

                        </div>

                        <div className="pos-r mt30">

                            <div className="c pos-r">

                                {

                                    annoncesUser

                                    ?

                                    <div className="grid4 articles mb30 m_p0">

                                        { annoncesUser.map(element => 
                                        
                                            <div key={element.idA} className='m_mb_20'>

                                                <Link to={'/annonce/' + element.idA} className="article bg6 cl2 d-block">
                                                    <div className="img">
                                                        <img src={ PHOTOS_ROOT + "small/" + element.name + ".jpg"} alt={element.titre} />
                                                    </div>
                                                    <div className="cnt">
                                                        <div className="flex m_flex">
                                                            <span className='price'>{element.prix}€</span>

                                                            {

                                                            likes.includes(element.idA)

                                                            ?

                                                                <span className='icon cl4' onClick={e => favoris(e, element.idA)}>k</span>

                                                            :

                                                                <span className='icon cl2' onClick={e => favoris(e, element.idA)}>j</span>

                                                            }

                                                            {/* <span className='icon cl4'>k</span> */}
                                                        </div>
                                                        <span className='h bg3 opt'>{element.taille}</span>
                                                        <span className="t h">{element.titre}</span>

                                                    </div>

                                                </Link>

                                                <span className="btn brc4 brc1solid cl5 bg4 ta-center d-block mt10" onClick={ (e) => addArticle(e, element.idA, element.prix)}>Ajouter</span>
                                            
                                            </div>

                                        )}
                                        
                                    </div>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                }

                                {(error !== '') && <p className="error">{error}</p>}

                                <div className="bg7 flex p10 br5 m_flex">

                                    <div className="flex ai-center">

                                        <span className="fw5 fz11">{ price } €</span>

                                    </div>

                                    <span className="btn brc4 brc1solid cl5 bg4 ta-center" onClick={e => commander() }>Créer un lot</span>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )

}

export default Lot