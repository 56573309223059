import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { WEBROOT, APP_NAME, PHOTOS_ROOT } from '../constants/general'

import { Text } from '../constants/Text'

import { Context } from '../class/Context'

import Func from '../class/Func'

import Annonces from '../class/Annonces';

const publier = async (e, setError, navigate) =>{

    e.preventDefault()

    let inputs = e.target

    const results = await Annonces.publier(inputs)

    if(results.success){

        Func.setFlash(results.message)

        navigate('/mon-profile/articles')

    }else{

        setError(results.message)

        window.scrollTo(0, 0)

    }

}

const uploadPhotos = (e, setError, setPhotos, photos, setLoading) => {

    let files = e.target.files

    //let newPhotosUrls = []

    if(files.length <= 6){

        //setPhotos(files)

        Array.from(files).forEach(photo => {

            upload(photo, setPhotos, photos, setError, setLoading)
            
            //newPhotosUrls.push(URL.createObjectURL(photo))

        })

        //setPhotosUrls(newPhotosUrls)

    }else{

        setError(Text.nombre_de_photos)

        e.target.files = null

    }

}

const upload = async (file, setPhotos, photos, setError, setLoading) => {

    if(file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est survenu.")

    setLoading(true)

    let results = await Annonces.photoUpload(file)

    setLoading(false)

    if (results.success){

        // if(photos == undefined){
        //     photos = []
        // }
        
        // let new_element = photos
        // new_element.push(results.data)

        // setPhotos([])

        // setInterval(function(){
        //     setPhotos(new_element)
        // }, 1000)

        getImages(setPhotos)

    }else{

        setError(results.message)
        
    }

}

const getImages = async (setPhotos) => {

    let results = await Annonces.getPhotosPublier()

    setPhotos(results.data)

}

const uploadPhotosInput = () => {

    document.querySelector('#uploadPhotosInput').click()

}

const supprimerPhoto = async (e, id) => {

    let t = e.target.parentNode

    t.style.opacity = 0.5

    let results = await Annonces.supprimerPhoto(id)

    if (results.success){

        t.style.display = 'none'

    }else{

        alert(results.message)

    }

}

function Vendre() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [photos, setPhotos] = useState([])
    const [photosUrls, setPhotosUrls] = useState([])

    const [categorie, setCategorie] = useState(1)

    const [mode_envoi, setModeEnvoi] = useState('enveloppe')
    const [type_envoi, setTypeEnvoi] = useState('poste')
    const [poids, setPoids] = useState('250')

    const change_mode_envoi = (e) => {

        setModeEnvoi(e.target.value)

        setPoids('250')

        if(e.target.value == 'enveloppe') setTypeEnvoi('poste')
        
    }

    const noWheel = (e) => {

        e.target.blur()
    
        e.stopPropagation()
    
        setTimeout(() => {
          e.target.focus()
        }, 0)
    
    }

    useEffect(() => {

        // Func.session(context, navigate, '/connexion', true)

        if((context.session !== true)) navigate('/connexion')

        document.title = "Vendre - " + APP_NAME

        document.body.classList.remove('mn')

        Func.choixSelect('#taille ._choix')

        Func.choixSelect('#poids_colis ._choix')

        Func.choixSelect('#mode_envoi ._choix')

        Func.choixSelect('#type_envoi ._choix')

        getImages(setPhotos)
        
    }, [])


    return (
        <div className="">
            
            <Header />

            <HeaderBox />

        <div className='content pos-r'>

            <div className="c">

                <span className='cl2 textUnd fz09'>Vends ton article</span>

                {(error !== '') && <p className="error">{error}</p>}

                <form method="POST" className="gla-form" encType='multipart/form-data' onSubmit={(e) => publier(e, setError, navigate)}>
                    
                    <div className="bg7 br8 p20 mt20 ta-center">

                        <div className="col-9 m0a">
                        
                            
                        { 
                        
                            loading

                            ?

                            <div className='flex jc-center mt50 mb50'>
                                <div className="lds-dual-ring col"></div>
                            </div>

                            :
                            
                            <span className="btn brc3 brc1solid cl1 bg3 ta-center mb10 col-3 textUp" onClick={ (e) => uploadPhotosInput()}>Ajouter des photos</span>
                        
                        }  
                    
                            <span className='d-block'>Ajoute jusqu’à 6 photos.</span>

                            <Link to={'/page/astuces'} className='cl3 textUnd'>Voir astuces</Link>
                                    
                            <input type="file" name='uploadPhotosInput' id='uploadPhotosInput' accept='image/*' multiple maxLength='6' onChange={ e => uploadPhotos(e, setError, setPhotos, photos, setLoading) } className="d-none"/>

                        </div> 

                        { 
                        
                            photos

                            &&

                            <div className='flex m_flex m_f_wrap jc-center m0a pt20 vender_img'>

                                {

                                    photos.map(image =>

                                    <div key={image.idI} style={{width: '100px', margin: '10px'}}>
                                        <img src={PHOTOS_ROOT + 'small/' + image.name + '.jpg'} style={{width: '100px',height: '100px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover'}} />
                                        <span className='btn cl3 icon' onClick={(e) => supprimerPhoto(e, image.idI)}>w</span>
                                    </div>

                                    )

                                }

                            </div>
                        
                        }  
                        

                        { photosUrls.map(image => 
                        
                            <div key={image.idI} style={{width: '100px', margin: '10px'}}>
                                <img src={image} style={{width: '100px',height: '100px', margin: '10px', borderRadius: '5px', objectFit: 'cover'}} />
                            </div>
                            
                            )
                            
                        }  

                    </div>

                    <div className="bg7 br8 p20 mt20 ta-center">
                        
                            <div className="col-9 m0a">
                                <label>
                                    <span>Catégorie</span>

                                    <select id='categorie' name='categorie' onChange={e => setCategorie(e.target.value)}>
                                        <option value="1">Plantes</option>
                                        <option value="2">Accessoires</option>
                                        <option value="3">Pots</option>
                                        <option value="4">Livres</option>
                                        <option value="5">Engraits et autres</option>
                                    </select>
                                </label>
                                
                                {

                                    categorie == 1

                                    &&

                                    <label>
                                        <span>Type de plantes</span>

                                        <select id='type' name='type'>
                                            <option value="1">Plantes vertes</option>
                                            <option value="2">Plantes rares</option>
                                            <option value="6">Plantes aromatiques</option>
                                            <option value="3">Plantes grasses</option>
                                            <option value="4">Plantes fleuries</option>
                                            <option value="7">Graines et Bulbes</option>
                                            <option value="5">Autres</option>
                                        </select>
                                    </label>

                                }

                                {

                                categorie == 2

                                &&

                                <label>
                                    <span>Type d'accessoires</span>

                                    <select id='type' name='type'>
                                        <option value="1">Arrosoirs</option>
                                        <option value="2">Tuteurs</option>
                                        <option value="3">Oyas</option>
                                        <option value="4">Autres</option>
                                    </select>
                                </label>

                                }

                                {

                                categorie == 3

                                &&

                                <label>
                                    <span>Type de pots</span>

                                    <select id='type' name='type'>
                                        <option value="1">Terre cuite</option>
                                        <option value="2">Plastique</option>
                                        <option value="3">Ceramique</option>
                                        <option value="4">Autres</option>
                                    </select>
                                </label>

                                }

                                {

                                categorie == 4

                                &&

                                <label>
                                    <span>Type de livres</span>

                                    <select id='type' name='type'>
                                        <option value="1">Botanique</option>
                                        <option value="2">Potager</option>
                                        <option value="3">Arbres</option>
                                        <option value="4">Herboristerie</option>
                                        <option value="5">Autres</option>
                                    </select>
                                </label>

                                }

                                {

                                categorie == 5

                                &&

                                    <select id='type' name='type' style={{display: 'none'}}>
                                        <option value="1" selected>autre</option>
                                    </select>

                                }

                                <label>
                                    <span>Titre</span>
                                    <input type="text" name="titre" placeholder="ex : Alocasia Zebrina" rows="1" required={true} />
                                </label>

                                <label>
                                    <span>Description</span>
                                    <textarea name="description" placeholder="ex : Alocasia de taille moyenne (45 cm)  qui aime la lumière…" required={true}></textarea>
                                </label>

                                <label className='mb0'>
                                    <span>Prix (€)</span>
                                    <input type="number" name="prix" placeholder="0,00€" step='0.01' required={true} min={1} onWheel={ e => noWheel(e) }/>
                                </label>
                                
                            </div>

                    </div>

                    <div className="bg7 br8 p20 mt20">

                        <div className="col-9 m0a">
                        
                            <div className='label m_d_block'>
                                <label><span>Taille</span></label>
                                <div className="flex col-65 m_col_10 m_mt_20 jc-start m_flex m_f_wrap" id='taille'>

                                    <div className='ta-center mr30 _choix' style={{ display: categorie == 1 ? 'block' : 'none'}}>
                                        <span className="opt bg2 h ta-center icon">x</span>
                                        <p className='fz09'>Graine</p>
                                        <input type='radio' value='Gr' name='taille'/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center bg3">S</span>
                                        <p className='fz09'>5-30 cm</p>
                                        <input type='radio' value='S' name='taille' defaultChecked/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center">M</span>
                                        <p className='fz09'>30-60 cm</p>
                                        <input type='radio' value='M' name='taille'/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center">L</span>
                                        <p className='fz09'>60-90 cm</p>
                                        <input type='radio' value='L' name='taille'/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center">XL</span>
                                        <p className='fz09'>90-130 cm</p>
                                        <input type='radio' value='XL' name='taille'/>
                                    </div>
                                    
                                </div>
                            </div>

                            <label className='mb0'>
                                <span>Conseils d’entretien ( optionnel ) : </span>
                                <textarea name="conseils" placeholder="ex : Parfaite pour sublimer votre salle de bain !"></textarea>
                            </label>

                        </div>   

                    </div>

                    <div className="bg7 br8 p20 mt20">

                        <div className="col-9 m0a">
                            
                            <div className='label'>

                                <div className='col-3'>
                                    <label><span className='mb10 d-block'>Mode d'expédition</span></label>
                                    
                                    {

                                        mode_envoi == 'colis'

                                        &&

                                        <p className='fz07'>Favorise ta boite aux lettre et l’acheteur pourra aussi récupérer son colis en point relais. (À condition que ta plante soit d’accord)</p>
                                    
                                    }

                                </div>

                                <div className='col-65 flex jc-start m_col_4 m_mt_20'>

                                    <div className="flex col-3 mr20 m_flex" id='mode_envoi'>

                                        <div className='ta-center mr30 _choix'>
                                            <span className="opt bg2 h ta-center icon bg3">c</span>
                                            <p className='fz09'>Enveloppe</p>
                                            <input type='radio' value='enveloppe' name='mode_envoi' checked={mode_envoi == 'enveloppe'} onChange={ change_mode_envoi }/>
                                        </div>

                                        <div className='ta-center mr30 _choix'>
                                            <span className="opt bg2 h ta-center icon">s</span>
                                            <p className='fz09'>Colis</p>
                                            <input type='radio' value='colis' name='mode_envoi' checked={mode_envoi == 'colis'} onChange={ change_mode_envoi }/>
                                        </div>

                                    </div>

                                    <div className="flex col-4 m_flex m_mt_20" id='type_envoi' style={{paddingTop: '7px'}}>

                                        <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'none' : 'block'}}>
                                            <span className={ `opt bg2 h ta-center mini ${type_envoi == 'poste' && 'bg3' }` }></span>
                                            <p className='fz09'>Bureau de poste</p>
                                            <input type='radio' value='poste' name='type_envoi' checked={type_envoi == 'poste'} onChange={ e => setTypeEnvoi(e.target.value) }/>
                                        </div>

                                        <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'none' : 'block'}}>
                                            <span className={ `opt bg2 h ta-center mini ${type_envoi == 'boite' && 'bg3' }` }></span>
                                            <p className='fz09'>Boite aux lettres</p>
                                            <input type='radio' value='boite' name='type_envoi' checked={type_envoi == 'boite'} onChange={ e => setTypeEnvoi(e.target.value) }/>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div> 

                        <div className="col-9 m0a m_mt_40">
                        
                            <div className='label'>

                                <label><span>Poids de l’enveloppe/colis</span></label>
                                
                                <div className="flex col-65 jc-start fz09 m_col_10 m_flex m_f_wrap m_mt_20" id='poids_colis'>
                                        
                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'block' : 'none'}}>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 20 g</p>
                                        <input type='radio' value='20' name='poids_colis' checked={poids == '20'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'block' : 'none'}}>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 100 g</p>
                                        <input type='radio' value='100' name='poids_colis' checked={poids == '100'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center bg3 mini"></span>
                                        <p className='fz09'>Max 250 g</p>
                                        <input type='radio' value='250' name='poids_colis' checked={poids == '250'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 500 g</p>
                                        <input type='radio' value='500' name='poids_colis' checked={poids == '500'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'colis' ? 'block' : 'none'}}>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 750 g</p>
                                        <input type='radio' value='750' name='poids_colis' checked={poids == '750'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 1 kg</p>
                                        <input type='radio' value='1000' name='poids_colis' checked={poids == '1000'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 2 kg</p>
                                        <input type='radio' value='2000' name='poids_colis' checked={poids == '2000'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'colis' ? 'block' : 'none'}}>
                                        <span className="opt bg2 h ta-center mini"></span>
                                        <p className='fz09'>Max 5 kg</p>
                                        <input type='radio' value='5000' name='poids_colis' checked={poids == '5000'} onChange={ e => setPoids(e.target.value) }/>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>

                        <div className="col-9 m0a">

                            <label className='mb0'>

                                <div>
                                    <span>Poids exacte (g)</span>
                                    <p className='fz08'>Permet de simplifier l’achat en lot</p>
                                </div>

                                <input type="number" name="poids" placeholder="Ex : 120 g" required={true} />
                            </label>

                        </div>

                    </div>

                    <div className="ta-end mt20">
                        <input type="submit" name="submit" value="Publier" className="btn bg3 cl5 brc3 brc1solid" />
                    </div>
                    
                </form>
            </div>

        </div>

            <Footer/>

        </div>   
    )

}

export default Vendre