import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile'

import { APP_NAME } from '../../constants/general'    

function PlantSittingPublique() {

    const { id } = useParams()

    useEffect(() => {

        document.title = "Plant Sitting - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">

                    <a href="" className='cl2 textUnd fz09'>Profil</a>
                    
                    <HeaderProfile id={ id }/>

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        <ProfileMenu data="plant-sitting" id={ id }/>

                        <div className="sittings grid5">
                            <div className="service bg9 brc4">
                                <div className="place">
                                    <span className="icon fz18 mr5">i</span>
                                    <span>Paris, 9eme</span>
                                </div>
                                <div className="price">12 € / passage</div>
                                
                                <ul className='brc4'>
                                    <li><span className='icon cl4'>q</span>Arrosage</li>
                                    <li><span className='icon'>q</span>Gardiennage</li>
                                </ul>

                                <div className="bottom">
                                    <span>2 favoris</span>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default PlantSittingPublique