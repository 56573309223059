import React, { useState, useEffect } from 'react';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import  User from '../../class/User'

import  { Text } from '../../constants/Text'

import  Func from '../../class/Func'


function Contact() {

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const envoyer = async (e) => {

        e.preventDefault()
    
        let inputs = e.target
    
        let name = inputs.name
    
        let email = inputs.email
    
        let numero = inputs.numero

        let message = inputs.message

        // contact@sistersgreen.fr
    
        if(name.value !== "" && email.value !== "" && numero.value !== "" && message.value !== ""){
    
            const results = await User.contactForm(inputs)
        
            if(results.success){
        
                setSuccess(results.message)
        
            }else{
        
                setError('Erreur. '+ results.message)
            }
    
           
        }else{
          
            setError('Assurez-vous que tout les champs sont remplis')

        }
       
    }

    useEffect(() => {

        document.title = "Contactez-nous - " + APP_NAME

        window.scrollTo(0, 0)

    }, [])


    return (

        <div>

            <Header />

            <div className="register">

                <HeaderBox />

                <div className='c pos-r flex pt80 pb80 ai-center jc-between m_p20'>

                    <div className='col-5 fz08 m_mt_40'>

                        <p className='mb20'>Bienvenue dans cet espace végétal dédié aux échanges et à la passion pour les plantes ! Nous sommes ravis de vous aider et de répondre à toutes vos questions.</p>

                        <p className='mb20'>Assistance aux Vendeurs et Acheteurs Si vous êtes un vendeur ou un acheteur sur Sisters Green et que vous avez des questions sur le processus de vente, les paiements, la livraison ou toute autre question, notre équipe est là pour vous guider. N'hésitez pas à contacter le jardin des réclamations sur messenger.</p>
                    
                        <p>Collaborations et Partenariats Nous sommes ouverts aux opportunités de collaboration avec d'autres passionnés de plantes, entreprises locales ou initiatives vertes. Si vous avez des idées de partenariat, des propositions de collaboration ou des projets conjoints à discuter, veuillez nous contacter. Nous croyons en la puissance de la communauté pour faire croître notre amour commun pour les plantes.</p>

                    </div>

                    <div className="ta-center col-4 m_mt_40">

                        <h1 className='mb30 fw4'>Contactez-nous</h1>

                        {(error !== '') && <p className="error">{error}</p>}
                        {(success !== '') && <p className="succes">{success}</p>}

                        <form method="POST" className="gla-form" onSubmit={(e) => envoyer(e)}>

                            <input type="text" name="name" placeholder="Votre nom" required={true} />

                            <input type="text" name="email" placeholder="Votre adresse E-mail" required={true} />

                            <input type="text" name="numero" placeholder="Votre numéro de téléphone" required={true} />

                            <input type="text" name='message' placeholder='Votre message' required={true} />

                            <input type="submit" name="log" value="Envoyer" className="btn bg4 cl1 brc1solid brc4" />

                            <p>Ensemble, cultivons la beauté naturelle !</p>

                            <p className='cl4 mt20 fz09'>L'équipe Sisters Green</p>

                        </form>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )

}

export default Contact