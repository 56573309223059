import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { APP_NAME } from '../constants/general'

import User from '../class/User'

function MotDePasse() {
    
    const navigate = useNavigate()

    const [ get_params ] = useSearchParams()
    
    const [loading, setLoading] = useState(false)

    const [flash, setFlash] = useState('')

    const [page, setPage] = useState(1)

    const [valideToken, setValideToken] = useState(false)

    const [userId, setUserId] = useState(false)
    const [userToken, setUserToken] = useState(false)


    const reset = async (e) => {

        e.preventDefault()
    
        let inputs = e.target

        setLoading(true)
    
        const results = await User.resetPasswordLink(inputs.email.value)

        setLoading(false)

        if(results.success){

            setFlash({message: results.message, type: "succes"})

            inputs.email.value = ''

        }else{
            
            setFlash({message: results.message, type: "error"})

        }
    
    }

    const checkTokenPassword = async (email, token) => {

        setLoading(true)
    
        const results = await User.checkTokenPassword(email, token)

        setLoading(false)

        if(results.success){

            // setFlash({message: results.message, type: "succes"})

            setUserId(results.id)
            setUserToken(results.token)

            setValideToken(true)

        }else{

            navigate('/404')
            
            // setFlash({message: results.message, type: "error"})

            // setValideToken(false)

        }
    
    }

    const resetPassword = async (e) => {

        e.preventDefault()
    
        let inputs = e.target

        setLoading(true)
    
        const results = await User.resetPassword(inputs, userId, userToken)

        setLoading(false)

        if(results.success){

            setFlash({message: results.message, type: "succes"})

            inputs.password.value = ''
            inputs.password_verif.value = ''

            setValideToken(false)

        }else{
            
            setFlash({message: results.message, type: "error"})

        }
    
    }

    useEffect(() => {

        let email = get_params.get("email")
        let token = get_params.get("token")

        if(email == null || token == null){

            setPage(1)

        }else{

            setPage(2)

            checkTokenPassword(email, token)

        }

        document.title = "Réinitialiser mon mot de passe - " + APP_NAME

    }, [])

    return (

        <div>

            <Header />

            <div className="register pos-r content">

                <HeaderBox />

                <div className='pos-r m_mt_40'>

                    <div className="col-3 m0a pt80 pb80 ta-center m_p20">

                        {

                            page == 1

                            ?

                            <>
                            
                                <h1 className='mb30 fw4'>Je réinitialise mon mot de passe</h1>

                                <form method="POST" className="gla-form mt30" onSubmit={(e) => reset(e)}>
                                    
                                    { flash !== '' && <p className={ flash.type + " fz09"}>{ flash.message }</p>}

                                    <input type="email" name="email" placeholder="E-mail ..." required="true" />

                                    {

                                        !loading

                                        ?

                                            <input type="submit" name="log" value="Réinitialiser" className="btn bg4 cl1 brc1solid brc4"/>

                                        :

                                            <div className='flex jc-center'>
                                                <div className="lds-dual-ring col"></div>
                                            </div>

                                    }

                                </form>

                            </>

                            :

                            <>

                                { flash !== '' && <p className={ flash.type + " fz09"}>{ flash.message }</p>}

                                {

                                    !loading

                                    ?

                                        <>
                                    
                                            {

                                                valideToken
                                                
                                                &&

                                                <>

                                                    <h1 className='mb30 fw4'>Je réinitialise mon mot de passe</h1>

                                                    <form method="POST" className="gla-form mt30" onSubmit={(e) => resetPassword(e)}>

                                                        <input type="password" name="password" placeholder="Nouveau mot de passe ..." required="true" minLength={6}/>
                                                        <input type="password" name="password_verif" placeholder="Retapper nouveau mot de passe ..." required="true" minLength={6}/>

                                                        <input type="submit" name="log" value="Réinitialiser" className="btn bg4 cl1 brc1solid brc4"/>
                                        
                                                    </form>

                                                </>

                                            }

                                        </>

                                    :

                                        <div className='flex jc-center'>
                                            <div className="lds-dual-ring col"></div>
                                        </div>

                                }
                                
                            </>

                        }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
        
    )

}

export default MotDePasse