import { API_ROOT, API_SECRET } from '../constants/general';

class User {

    static register = (input) => {

        let data = fetch(API_ROOT + "register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                password: input.pass.value,
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static registerSocial = (username, userInfos, type) => {

        let data = fetch(API_ROOT + "registerSocial", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                username: username,
                email: userInfos.email,
                picture: userInfos.picture,
                type: type
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static login = (input) => {
        
        let data = fetch(API_ROOT + "login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                api_secret: API_SECRET,
                login: input.email.value,
                password: input.pass.value,
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static session = (infos = false) => {
        let data = fetch(API_ROOT + "session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                infos: infos
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static validerEmail = (email, token) => {
        let data = fetch(API_ROOT + "users/validerEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: email,
                token: token
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static renvoyerValidation = () => {
        let data = fetch(API_ROOT + "users/renvoyerValidation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }
    
    static resetPasswordLink = (email) => {
        let data = fetch(API_ROOT + "users/resetPasswordLink", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: email
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static checkTokenPassword = (email, token) => {
        let data = fetch(API_ROOT + "users/checkTokenPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email,
                token
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static resetPassword = (inputs, id, token) => {
        let data = fetch(API_ROOT + "users/resetPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                password: inputs.password.value,
                password_verif: inputs.password_verif.value,
                id: id,
                token: token
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static profile = (id) => {
        let data = fetch(API_ROOT + "users/profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static connected = async (setSession) => {

        if (localStorage.getItem('idU') !== undefined && localStorage.getItem('app_token') !== undefined) {

            const results = await this.session()

            if (!results.success) {

                localStorage.removeItem('idU')
                localStorage.removeItem('app_token')
                localStorage.removeItem('name')
                localStorage.removeItem('email')
                localStorage.removeItem('avatar')

                setSession(false)

            } else {

                setSession(true)

            }

            return results.success

        } else {

            return false
        }
    }

    static photoUpload = (file) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        
        let data = fetch(API_ROOT + "users/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static modifierInformations = (input) => {
        let data = fetch(API_ROOT + "users/modifierInformations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                name: input.name.value,
                names: input.names.value,
                pays: input.pays.value,
                ville: input.ville.value,
                rue: input.rue.value,
                postal: input.postal.value,
                numero: input.numero.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static modifierMotdepasse = (input) => {
        let data = fetch(API_ROOT + "users/modifierMotdepasse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                password_old: input.password_old.value,
                password: input.password.value,
                password_r: input.password_r.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Social Login

    static links = () => {

        let data = fetch(API_ROOT + "users/connect/links", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static googleConnect = (code) => {

        let data = fetch(API_ROOT + "users/connect/google", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                code: code
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static facebookConnect = (code) => {

        let data = fetch(API_ROOT + "users/connect/facebook", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                code: code
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    // Avis ---------

    static avis = (id = 0) => {

        let data = fetch(API_ROOT + "users/avis", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data

    }

    static ajouterAvis = (id, input) => {
        let data = fetch(API_ROOT + "users/ajouterAvis", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id,
                note: input.note.value,
                avis: input.avis.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Charte green ---------

    static signer = () => {
        let data = fetch(API_ROOT + "users/signerCharteGreen", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Messages

    static messages = () => {
        let data = fetch(API_ROOT + "users/messages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    
    // Delete account

    static deleteAccount = (input) => {
        let data = fetch(API_ROOT + "users/deleteAccount", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Contact

    static contactForm = (input) => {
        let data = fetch(API_ROOT + "contactForm", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                numero: input.numero.value,
                message: input.message.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

}


export default User