import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { WEBROOT, APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import User from '../class/User'

import Func from '../class/Func'


const Connect = async (e, setError,navigate, setSession) => {

    e.preventDefault()

    let inputs = e.target

    let pass= inputs.pass

    let email = inputs.email

    if( email.value !=='' && pass.value !==''){

        const results = await User.login(inputs)

        if(results.success){

            console.log(results)

            Func.saveSession(results.data, results.likes, setSession)

            navigate('/mon-profile/articles')

         }else{

            setError(results.message)
                         
         }
  
    }else{

        setError( 'Assurez-vous que tout les champs sont remplis')

    }

}

function ConnexionEmail() {
    
    const navigate = useNavigate()

    const context = useContext(Context)

    const [loading, setLoading] =useState(false)

    const [error, setError] = useState('')

    const [password, setPassword] = useState('password')

    useEffect(() => {

        document.title = "Connexion avec adresse email - " + APP_NAME

    }, [])

    return (

        <div>

            <Header />

            <div className="register pos-r content">

                <HeaderBox />

                <div className='pos-r m_mt_40'>

                    <div className="col-3 m0a pt80 pb80 ta-center m_p20">

                        <h1 className='mb30 fw4'>Connecte toi avec l'adresse email ou le nom d'utilisateur</h1>

                        <form action="" method="POST" className="gla-form" onSubmit={(e) => Connect(e,setError,navigate, context.setSession)}>
                            
                            {(error !== '') && <p className="error">{error}</p>}  

                            <input type="text" name="email" placeholder="E-mail / Nom utilisateur" required="true" />

                            <div className='input_eye'>

                                <input type={password} name="pass" placeholder="Mot de passe" required="true" />
                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" onClick={e => password == 'password' ? setPassword('text') :  setPassword('password') }><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>

                            </div>

                            <input type="submit" name="log" value="Se connecter" className="btn bg4 cl1 brc1solid brc4"/>

                            <p className='fz09'>Je <Link to={'/recuperer-mot-de-passe'} className='cl3'>réinitialise</Link> mon mot de passe !</p>

                        </form>

                    </div>

                </div>

            </div>

            <Footer />

        </div>
        
    )

}

export default ConnexionEmail