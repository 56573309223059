import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func' 

import Discussions from '../../class/Discussions'

import { DiscussionsCard } from '../../components/profile-prive'

function Profile() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const id = 0

    const style = `footer{display: none}`

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Mes discussions - " + APP_NAME

        document.body.classList.remove('mn')

    }, [])

    return (

        <div>

         <Header />
                
            <div>

            <HeaderBox />

            <div className='pos-r discussions_page'>

                <div className="c flex">

                    <DiscussionsCard discussion_id={ id }/>

                    <div className='col-7 p20'>

                        <p className='fz08'>Sélectionne une discussion pour afficher les messages !</p>

                    </div>

                </div>

            </div>

            </div>

            <style>{style}</style>

            <Footer/>

        </div>

    )

}

export default Profile