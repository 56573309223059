import { API_ROOT, API_SECRET } from '../constants/general'

class Annonces {

    static publier = (inputs) => {

        let data = fetch(API_ROOT + "annonces/publier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                type: inputs.type.value,
                categorie: inputs.categorie.value,
                titre: inputs.titre.value,
                description: inputs.description.value,
                prix: inputs.prix.value,
                taille: inputs.taille.value,
                poids_colis: inputs.poids_colis.value,
                mode_envoi: inputs.mode_envoi.value,
                type_envoi: inputs.type_envoi.value,
                conseils: inputs.conseils.value,
                poids: inputs.poids.value
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static modifier = (inputs, id) => {

        let data = fetch(API_ROOT + "annonces/modifier", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            api_secret: API_SECRET,
            id_user: localStorage.getItem('idU'),
            app_token: localStorage.getItem('app_token'),
            type: inputs.type.value,
            categorie: inputs.categorie.value,
            titre: inputs.titre.value,
            description: inputs.description.value,
            prix: inputs.prix.value,
            taille: inputs.taille.value,
            poids_colis: inputs.poids_colis.value,
            mode_envoi: inputs.mode_envoi.value,
            type_envoi: inputs.type_envoi.value,
            conseils: inputs.conseils.value,
            poids: inputs.poids.value,
            id: id
        })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static mesAnnonces = (option) => {

        let data = fetch(API_ROOT + "annonces/mesAnnonces", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesById = (id) => {

        let data = fetch(API_ROOT + "annonces/annonceById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static masquer = (id) => {

        let data = fetch(API_ROOT + "annonces/masquer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static supprimer = (id) => {

        let data = fetch(API_ROOT + "annonces/supprimer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByIdCommander = (id) => {

        let data = fetch(API_ROOT + "annonces/annonceByIdCommander", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByLot = (lot) => {

        let data = fetch(API_ROOT + "annonces/annonceByLot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                lot: lot
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static acceuil = () => {

        let data = fetch(API_ROOT + "annonces/acceuil", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static photoUpload = (file, id = 0) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        formdata.append('id', id)
        
        let data = fetch(API_ROOT + "annonces/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static getPhotosPublier = () => {

        let data = fetch(API_ROOT + "annonces/getPhotosPublier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static supprimerPhoto = (id) => {

        let data = fetch(API_ROOT + "annonces/supprimerPhoto", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static annoncesByCategorie = (id, option) => {

        let data = fetch(API_ROOT + "annonces/annoncesByCategorie", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                categorie: id,
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByUser = (id, option) => {

        let data = fetch(API_ROOT + "annonces/annoncesByUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                user: id,
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static favoris = () => {

        let data = fetch(API_ROOT + "annonces/favoris", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static ajoutFavoris = (id) => {

        let data = fetch(API_ROOT + "annonces/ajoutFavoris", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id:id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static search = (query) => {

        let data = fetch(API_ROOT + "annonces/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                query:query
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static searchPage = (query) => {

        let data = fetch(API_ROOT + "annonces/searchPage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                query:query
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    // Offre

    static offreById = (annonce_id, offre_id) => {

        let data = fetch(API_ROOT + "annonces/offreById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                annonce_id: annonce_id,
                offre_id: offre_id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    // Livraison

    static livraison = (annonce_id) => {

        let data = fetch(API_ROOT + "annonces/livraison", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                annonce_id: annonce_id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static livraisonLot = (annonce_id) => {

        let data = fetch(API_ROOT + "annonces/livraisonLot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                annonce_id: annonce_id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getPointsRelais = () => {

        let data = fetch(API_ROOT + "annonces/getPointsRelais", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                // annonce_id: annonce_id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    // Paiement

    static payer = (card_id, annonce_id, offre_id, type_livraison, points_relais) => {

        let data = fetch(API_ROOT + "annonces/payer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                card_id: card_id,
                annonce_id: annonce_id,
                offre_id: offre_id,
                type_livraison: type_livraison,
                points_relais: points_relais
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static payerLot = (card_id, lot) => {

        let data = fetch(API_ROOT + "annonces/payerLot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                card_id: card_id,
                lot: lot
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}

export default Annonces