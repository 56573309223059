import React, { useState, useEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import { APP_NAME } from '../constants/general'

import Func from '../class/Func'

import User from '../class/User'

const HeaderProfile = (props) => {
    
    const navigate = useNavigate()
    
    const [infos, setInfos] = useState(false)

    const myInfos = async () => {

        let results = await User.profile(props.id)

        if(results.success){

            setInfos(results.data)

            document.title = results.data.name + " - " + APP_NAME

        }else{

            navigate('/404')

        }

    }

    useEffect(() => {

        myInfos()

    }, [])

    return (
        <div className="bg7 br8 p20 mt20">
            <div className="flex pos-r">

                <div className="flex m_flex">
                    <div className="av large mr30">
                        
                        <img src={ Func.avatar(infos.avatar)} className="avatar_img"/>

                    </div>

                    <div className='m_col-7 m_ml_15'>
                        <div className="flex m_flex ai-center fz09 prfl flex-wrap flex-start">

                            <span className='t cl2 fw5 m_col_10'>{ infos.name }</span>

                            { Func.note(infos.note) } <span className='ml10'>( { infos.nbr_note } avis)</span>
                            
                        </div>

                        {

                            infos.pays

                            &&

                            <div className="flex jc-start mt20 m_mt_10 ai-center">
                                <span className="icon mr10 fz15">i</span>
                                <span className='h'>{infos.pays}{infos.ville != "" && ', ' + infos.ville}</span>
                            </div>

                        }

                        {
                            infos.email_token == 0

                            &&

                            <div className='mt20 m_mt_10'>

                            <span>Informations vérifiées :</span>
                                <ul>
                                    { infos.email_token == 0 && <li>Email</li>}
                                    { infos.charte_green !== 0 && <li className='cl2'><Link to={'/page/la-chartegreen'} className='cl2'>Charte Green signée</Link></li>}
                                </ul>

                            </div>

                        }

                    </div>

                </div>

                <div>

                    {/* <Link to={'/profile/'+ infos.idU +'/envoyer-message'} className="btn brc4 brc1solid cl4 bgt ta-center d-block mb10">Envoyer un message</Link> */}

                </div>

            </div>

        </div>
    )

}

const ProfileMenu = (props) => {

    let not_active = "btn cl2 bgt mr10"
    let active = "btn brc4 brc2solid cl1 bg4 mr10"

    return (
        <div className="wrap textUp fw5 mb20">
            <Link to={'/profile/' + props.id} className={props.data == 'articles' ? active : not_active }>Pépinière</Link>
            <Link to={'/profile/' + props.id + '/evaluations'} className={props.data == 'evaluations' ? active : not_active }>évaluations</Link>
            {/* <Link to={'/profile/' + props.id + '/plant-sitting'} className={props.data == 'plant-sitting' ? active : not_active }>Plant-sitting</Link> */}
        </div>
    )

}

export { HeaderProfile, ProfileMenu };