import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../components/imports'

import { APP_NAME } from '../constants/general'

import Annonces from '../class/Annonces'

const getArticles = async (setArticles) => {

    let results = await Annonces.acceuil()

    setArticles(results.ajouts_recent)

}

function Page_404() {

    const navigate = useNavigate();

    const [articles, setArticles] = useState(false)

    useEffect(() => {

        getArticles(setArticles)

        document.title = "Impossibe d'afficher cette page - Erreur 404 - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div>

            <HeaderBox />

                <div className="c1">

                    <div className='c pt100 pb20 ta-center'>
                        <h1>Erreur 404 - Page introuvable, Impossible d'afficher cette page</h1>
                    </div>

                    <div className="c pt80 pb40">

                        <h2 className='ta-center mb30'>Ajouts récents</h2>

                            {

                                articles

                                ?

                                    <div className="grid4 articles">

                                        { articles.map(element => <Article data={element} key={element.idA} />)}
                                        
                                    </div>

                                :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Page_404