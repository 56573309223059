import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../components/imports'

import { APP_NAME } from '../constants/general'

import Annonces from '../class/Annonces'

function Search() {

    const navigate = useNavigate()

    const [get_params] = useSearchParams()

    const [articles, setArticles] = useState(false)

    const [queryText, setQueryText] = useState(false)

    
    const getArticles = async (query) => {

        let results = await Annonces.searchPage(query)

        console.log(results)

        setArticles(results.data)

    }

    useEffect(() => {

        let query = get_params.get("query")

        if(query == null) navigate('/404')

        setQueryText(query)

        getArticles(query)

        document.title = query + " recherche - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div>

            <HeaderBox />

                <div className="c1">

                    <div className='c pt100 pb20 ta-center'>
                        <h1>Recherche : {queryText}</h1>
                    </div>

                    <div className="c pt80 pb40">

                        <h2 className='ta-center mb30'>Résultats trouvés {articles.length}</h2>

                            {

                                articles

                                ?

                                    articles.length > 0

                                    ?

                                    <div className="grid4 articles">

                                        { articles.map(element => <Article data={element} key={element.idA} />)}
                                        
                                    </div>

                                    :

                                    <p>Aucun résultat pour cette recherche "{queryText}"</p>

                                :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Search