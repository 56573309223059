import React, { useState, useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import Func from '../../class/Func'

import User from '../../class/User'

import { Context } from '../../class/Context'

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import PagesController from '../../class/Pages'

function CharteGreen() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [titre, setTitre] = useState(false)

    const [thisArticle, setThisArticle] = useState(false)

    const getArticle = async () => {

        let results = await PagesController.get('la-charte-green')

        if(results.success){

            setTitre(results.data.name)

            setThisArticle(results.data.content)

        }else{

            navigate('/404')

        }

    }

    const signer = async () => {

        let results = await User.signer()
        
        if(results.success){

            Func.setFlash(results.message)

            navigate('/mon-profile/articles')

        }else{

            alert('une erreur est servenue, impossible de signer la Charte Green.')

        }
    
    }

    useEffect(() => {

        Func.session(context, navigate)

        getArticle()

        document.title = "Signer la Charte Green - " + APP_NAME

    })

    return (

        <div>

            <Header />

            <div>

                <HeaderBox />

                <div className="cc content page_page">

                    <h1 className='h cl4 fw3 fz17 mb40'>{ titre }</h1>

                    {

                        thisArticle

                        ?

                            <>

                                <div dangerouslySetInnerHTML={ { __html: thisArticle } }></div>
                                
                            </>

                        :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="lds-dual-ring col"></div>
                            </div>

                        }

                    <div className='flex jc-end mt30'>

                        <span className='btn bg4 cl1 fz15' onClick={ signer }>Je signe</span>

                    </div>


                </div>

                <Footer />
                
            </div>

        </div>

    )

}

export default CharteGreen