import { API_ROOT, API_SECRET } from '../constants/general';

class Categories {

    static get = () => {

        let data = fetch(API_ROOT + "categories/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static categorieByUrl = (url) => {

        let data = fetch(API_ROOT + "categories/categorieByUrl", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                url: url
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

}


export default Categories