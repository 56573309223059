import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import PagesController from '../../class/Pages'

import { APP_NAME } from '../../constants/general'

function Pages() {

    const { url } = useParams()

    const navigate = useNavigate()

    const [article, setArticle] = useState(false)

    const getArticle = async () => {

        window.scrollTo(0, 0)

        if(url == null) navigate('/404')

        let results = await PagesController.get(url)

        if(results.success){

            setArticle(results.data)

            document.title = results.data.name + " - " + APP_NAME

        }else{

            navigate('/404')

        }

    
    }

    useEffect(() => {

        getArticle()

        document.body.classList.remove('mn')

    }, [url])


    return (
        <div>

            <Header />

            <div>

                <HeaderBox />

                    <div className="cc content page_page">

                        {

                        article

                        ?

                            <>

                                <h1 className='h cl4 fw3 fz17 mb40'>{ article.name }</h1>

                                <div dangerouslySetInnerHTML={ { __html: article.content } }></div>
                                
                            </>

                        :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="lds-dual-ring col"></div>
                            </div>

                        }

                    </div>

                <Footer />

            </div>

        </div>
    )

}

export default Pages