import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { WEBROOT, APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

function PlantSitting() {

    const navigate = useNavigate();

    useEffect(() => {

        document.title = "Plant Sitting - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />

            <div className="">

                <HeaderBox />

                <div className='content'>
                    
                    <div className='c ta-center'>

                        <h1>Bientôt disponible</h1>

                    </div>

                    {/* <div className="cc">

                        <span className='textUnd'>Plant-sitting</span>

                        <div className="flex jc-start mt30 mb30">

                            <img src={ 'image/plant-sitting.jpg' } className="col-2"/>

                            <div className='ml30 col-8'>
                                <h1 className='textUp fz15 fw4 mb20'>LE GARDIENNAGE et arrosage DE PLANTES,<br /> UN SERVICE communautaire</h1>
                                    <p className='fw3 fz09'>Que faire de ses plantes quand on part en vacances, que vous ayez des plantes d’intérieur où dans votre jardin la difficulté de garder les plantes vivantes reste la même, surtout avec le changement climatique que nous vivons et La solution du plant-sitting est parfaite, chez vous où chez le plant-sitter le choix vous revient en fonction du nombre de plantes et d’autres paramètres, confier ses clés n’est pas une mince à faire alors mettons ensemble des consignes pour que vous puissiez partir en vacances l’esprit tranquille.</p>
                            </div>

                            </div>

                            <a href="" className="btn brc4 brc2solid cl4 bgt ta-center d-block mb10 textUp fw6 mb20">Je PROPOSE un plant-sittING</a>

                            <a href="" className="btn brc4 brc2solid cl4 bgt ta-center d-block mb10 textUp fw6 mb30">Je recherche un plant-sitter</a>

                            <div className="services grid4">

                                <div className="service bg9 brc4">
                                    <div className="place">
                                        <span className="icon fz18 mr5">&#xef0a</span>
                                        <span>Paris, 9eme</span>
                                    </div>
                                    <div className="price">12 € / passage</div>

                                    <ul className='brc4'>
                                        <li><span className='icon cl4'>&#xeb82</span>Arrosage</li>
                                        <li><span className='icon'>&#xeb99</span>Gardiennage</li>
                                    </ul>

                                    <div className="bottom">
                                        <div className='user'>
                                            <div className="av ">
                                                <img src="assets/img/large/avatar.jpeg" alt="" />
                                            </div>
                                            <span className='icon'>&#xf186</span>
                                            <span>4,6</span>
                                        </div>
                                        <span className='icon cl4'>&#xee0a</span>
                                    </div>

                                </div>

                                <div className="service bg9 brc4">
                                    <div className="place">
                                        <span className="icon fz18 mr5">&#xef0a</span>
                                        <span>Paris, 9eme</span>
                                    </div>
                                    <div className="price">12 € / passage</div>

                                    <ul className='brc4'>
                                        <li><span className='icon cl4'>&#xeb82</span>Arrosage</li>
                                        <li><span className='icon'>&#xeb99</span>Gardiennage</li>
                                    </ul>

                                    <div className="bottom">
                                        <div className='user'>
                                            <div className="av ">
                                                <img src="assets/img/large/avatar.jpeg" alt="" />
                                            </div>
                                            <span className='icon'>&#xf186</span>
                                            <span>4,6</span>
                                        </div>
                                        <span className='icon cl4'>&#xee0a</span>
                                    </div>

                                </div>

                                <div className="service bg9 brc4">
                                    <div className="place">
                                        <span className="icon fz18 mr5">&#xef0a</span>
                                        <span>Paris, 9eme</span>
                                    </div>
                                    <div className="price">12 € / passage</div>

                                    <ul className='brc4'>
                                        <li><span className='icon cl4'>&#xeb82</span>Arrosage</li>
                                        <li><span className='icon'>&#xeb99</span>Gardiennage</li>
                                    </ul>

                                    <div className="bottom">
                                        <div className='user'>
                                            <div className="av ">
                                                <img src="assets/img/large/avatar.jpeg" alt="" />
                                            </div>
                                            <span className='icon'>&#xf186</span>
                                            <span>4,6</span>
                                        </div>
                                        <span className='icon cl4'>&#xee0a</span>
                                    </div>

                                </div>
                                <div className="service bg9 brc4">
                                    <div className="place">
                                        <span className="icon fz18 mr5">&#xef0a</span>
                                        <span>Paris, 9eme</span>
                                    </div>
                                    <div className="price">12 € / passage</div>

                                    <ul className='brc4'>
                                        <li><span className='icon cl4'>&#xeb82</span>Arrosage</li>
                                        <li><span className='icon'>&#xeb99</span>Gardiennage</li>
                                    </ul>

                                    <div className="bottom">
                                        <div className='user'>
                                            <div className="av ">
                                                <img src="assets/img/large/avatar.jpeg" alt="" />
                                            </div>
                                            <span className='icon'>&#xf186</span>
                                            <span>4,6</span>
                                        </div>
                                        <span className='icon cl4'>&#xee0a</span>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div> */}

                    </div>

                </div>

                <Footer />

            </div>

    )

}

export default PlantSitting