import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { WEBROOT, APP_NAME, PHOTOS_ROOT } from '../../constants/general'

import { Text } from '../../constants/Text'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Annonces from '../../class/Annonces';

const modifier = async (e, id, setError, navigate) =>{

    e.preventDefault()

    let inputs = e.target

    const results = await Annonces.modifier(inputs, id)

    if(results.success){

        Func.setFlash(results.message)

        navigate('/annonce/' + id)

    }else{

        setError(results.message)

        window.scrollTo(0, 0)

    }

}

const uploadPhotos = (e, setError, setPhotos, photos, setLoading, id) => {

    let files = e.target.files

    if(files.length <= 6){

        Array.from(files).forEach(photo => {

            upload(photo, setPhotos, photos, setError, setLoading, id)

        })

    }else{

        setError(Text.nombre_de_photos)

        e.target.files = null

    }

}

const upload = async (file, setPhotos, photos, setError, setLoading, id) => {

    if(file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est servenu.")

    setLoading(true)

    let results = await Annonces.photoUpload(file, id)

    setLoading(false)

    if (results.success){

        if(photos == undefined){
            photos = []
        }
        
        let new_element = photos
        new_element.push(results.data)

        setPhotos([])

        setInterval(function(){
            setPhotos(new_element)
        }, 1000)

    }else{

        setError(results.message)
        
    }

}

const uploadPhotosInput = () => {

    document.querySelector('#uploadPhotosInput').click()

}

const supprimerPhoto = async (e, id) => {

    let t = e.target.parentNode

    t.style.opacity = 0.5

    let results = await Annonces.supprimerPhoto(id)

    if (results.success){

        t.style.display = 'none'

    }else{

        alert(results.message)

    }

}

function ModifierAnnonce() {

    const { id } = useParams()

    const navigate = useNavigate()

    const context = useContext(Context)

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    //const [photos, setPhotos] = useState([])
    const [photosUrls, setPhotosUrls] = useState([])

    const [annonce, setAnnonce] = useState(false)
    const [photos, setPhotos] = useState(false)
    
    const [categorie, setCategorie] = useState(1)

    const [taille, setTaille] = useState(0)
    const [mode_envoi, setModeEnvoi] = useState(0)
    const [type_envoi, setTypeEnvoi] = useState(0)
    const [poids_colis, setPoidsColis] = useState(0)

    const getAnnonce = async () => {

        let results = await Annonces.annoncesById(id)
    
        if(results.success){
    
            if(results.data.idU !== localStorage.idU || (results.data.stt !== '0' && results.data.stt !== '5')) navigate('/404')
    
            setAnnonce(results.data)

            setPhotos(results.photos)

            setCategorie(results.data.categorie_id)

            setTaille(results.data.taille)
            setModeEnvoi(results.data.mode_envoi)
            setTypeEnvoi(results.data.type_envoi)
            setPoidsColis(results.data.poids_colis)
    
            document.title = results.data.titre + " - " + APP_NAME
    
        }else{
    
            navigate('/404')
    
        }
    
    }

    const change_this = (e, elem) => {

        elem(e.target.value)

    }

    const change_mode_envoi = (e) => {

        setModeEnvoi(e.target.value)

        if(e.target.value == 'enveloppe') setTypeEnvoi('poste')

    }

    const noWheel = (e) => {

        e.target.blur()
    
        e.stopPropagation()
    
        setTimeout(() => {
          e.target.focus()
        }, 0)
    
    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Modifier l'annonce "+ id +" - " + APP_NAME

        getAnnonce()

        Func.choixSelect('#taille ._choix')

        Func.choixSelect('#poids_colis ._choix')

        Func.choixSelect('#mode_envoi ._choix')

        Func.choixSelect('#type_envoi ._choix')
        
    }, [])


    return (
        <div className="">
            
            <Header />

            <HeaderBox />

        <div className='content pos-r'>

            <div className="c">

                <a className='cl2 textUnd fz09'>Modifier l'annonce N° { id }</a>

                {(error !== '') && <p className="error">{error}</p>}  

                <form method="POST" className="gla-form" encType='multipart/form-data' onSubmit={(e) => modifier(e, id, setError, navigate)}>
                    
                    <div className="bg7 br8 p20 mt20 ta-center">

                        <div className="col-9 m0a">
                        
                            
                        { 
                        
                            loading

                            ?

                            <div className='flex jc-center mt50 mb50'>
                                <div className="lds-dual-ring col"></div>
                            </div>

                            :
                            
                            <span className="btn brc3 brc1solid cl1 bg3 ta-center mb10 col-3 textUp" onClick={ (e) => uploadPhotosInput()}>Ajouter des photos</span>
                        
                        }  
                    
                            <span className='d-block'>Ajoute jusqu’à 6 photos.</span>

                            <Link to={'/page/astuces'} className='cl3 textUnd'>Voir astuces</Link>
                                    
                            <input type="file" name='uploadPhotosInput' id='uploadPhotosInput' accept='image/*' multiple maxLength='6' onChange={ e => uploadPhotos(e, setError, setPhotos, photos, setLoading, id) } className="d-none"/>

                        </div> 

                        { 
                        
                            photos

                            &&

                            <div className='flex m_flex m_f_wrap jc-center m0a pt20 vender_img'>

                                {

                                    photos.map(image =>

                                    <div key={image.idI} style={{width: '100px', margin: '10px'}}>
                                        <img src={PHOTOS_ROOT + 'small/' + image.name + '.jpg'} style={{width: '100px',height: '100px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover'}} />
                                        <span className='btn cl3 icon' onClick={(e) => supprimerPhoto(e, image.idI)}>w</span>
                                    </div>

                                    )

                                }

                            </div>
                        
                        }  
                        

                        { photosUrls.map(image => <img src={image} style={{width: '100px',height: '100px', margin: '10px', borderRadius: '5px', objectFit: 'cover'}} />) }  

                    </div>

                    <div className="bg7 br8 p20 mt20 ta-center">
                        
                            <div className="col-9 m0a">
                                <label>
                                    <span>Catégorie</span>

                                    <select id='categorie' name='categorie' onChange={e => setCategorie(e.target.value)} >
                                        <option value="1" selected={ annonce.categorie_id == "1" }>Plantes</option>
                                        <option value="2" selected={ annonce.categorie_id == "2" }>Accessoires</option>
                                        <option value="3" selected={ annonce.categorie_id == "3" }>Pots</option>
                                        <option value="4" selected={ annonce.categorie_id == "4" }>Livres</option>
                                        <option value="5" selected={ annonce.categorie_id == "5" }>Engraits et autres</option>
                                    </select>
                                </label>
                                
                                {

                                categorie == 1

                                &&

                                <label>
                                    <span>Type de plantes</span>

                                    <select id='type' name='type'>
                                        <option value="1" selected={ annonce.type == "1" }>Plantes vertes</option>
                                        <option value="2" selected={ annonce.type == "2" }>Plantes rares</option>
                                        <option value="6" selected={ annonce.type == "6" }>Plantes aromatiques</option>
                                        <option value="3" selected={ annonce.type == "3" }>Plantes grasses</option>
                                        <option value="4" selected={ annonce.type == "4" }>Plantes fleuries</option>
                                        <option value="7" selected={ annonce.type == "7" }>Graines et Bulbes</option>
                                        <option value="5" selected={ annonce.type == "5" }>Autres</option>
                                    </select>
                                </label>

                                }

                                {

                                categorie == 2

                                &&

                                <label>
                                <span>Type d'accessoires</span>

                                <select id='type' name='type'>
                                    <option value="1" selected={ annonce.type == "1" }>Arrosoirs</option>
                                    <option value="2" selected={ annonce.type == "2" }>Tuteurs</option>
                                    <option value="3" selected={ annonce.type == "3" }>Oyas</option>
                                    <option value="4" selected={ annonce.type == "4" }>Autres</option>
                                </select>
                                </label>

                                }

                                {

                                categorie == 3

                                &&

                                <label>
                                <span>Type de pots</span>

                                <select id='type' name='type'>
                                    <option value="1" selected={ annonce.type == "1" }>Terre cuite</option>
                                    <option value="2" selected={ annonce.type == "2" }>Plastique</option>
                                    <option value="3" selected={ annonce.type == "3" }>Ceramique</option>
                                    <option value="4" selected={ annonce.type == "4" }>Autres</option>
                                </select>
                                </label>

                                }

                                {

                                categorie == 4

                                &&

                                <label>
                                <span>Type de livres</span>

                                <select id='type' name='type' defaultValue={ annonce.type }>
                                    <option value="1" selected={ annonce.type == "1" }>Botanique</option>
                                    <option value="2" selected={ annonce.type == "2" }>Potager</option>
                                    <option value="3" selected={ annonce.type == "3" }>Arbres</option>
                                    <option value="4" selected={ annonce.type == "4" }>Herboristerie</option>
                                    <option value="5" selected={ annonce.type == "5" }>Autres</option>
                                </select>
                                </label>

                                }

                                {

                                categorie == 5

                                &&

                                <select id='type' name='type' style={{display: 'none'}}>
                                    <option value="1" selected>autre</option>
                                </select>

                                }

                                <label>
                                    <span>Titre</span>
                                    <input type="text" name="titre" placeholder="ex : L’Aspidistra Elatior" required={true} defaultValue={ annonce.titre }/>
                                </label>

                                <label>
                                    <span>Description</span>
                                    <textarea name="description" placeholder="ex : Parfaite pour sublimer votre salle de bain !" required={true} defaultValue={ annonce.description }></textarea>
                                </label>

                                <label className='mb0'>
                                    <span>Prix (€)</span>
                                    <input type="number" name="prix" placeholder="0,00€" step='0.01' required={true} defaultValue={ annonce.prix } min={1} onWheel={ e => noWheel(e) }/>
                                </label>
                                
                            </div>

                    </div>

                    <div className="bg7 br8 p20 mt20">

                        <div className="col-9 m0a">
                        
                            <div className='label'>
                                <label><span>Taille</span></label>
                                <div className="flex col-65 m_col_10 m_mt_20 jc-start m_flex m_f_wrap" id='taille'>

                                    <div className='ta-center mr30 _choix' style={{ display: categorie == 1 ? 'block' : 'none'}}>
                                        <span className={ annonce.taille == 'Gr' ? 'opt h ta-center bg3 icon' : 'opt bg2 h ta-center icon' }>x</span>
                                        <p className='fz09'>Graine</p>
                                        <input type='radio' value='Gr' name='taille' checked={ taille == 'Gr'} onChange={ e => change_this(e, setTaille) } key='Gr' />
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ annonce.taille == 'S' ? 'opt bg2 h ta-center bg3' : 'opt bg2 h ta-center' }>S</span>
                                        <p className='fz09'>5-30 cm</p>
                                        <input type='radio' value='S' name='taille' checked={ taille == 'S'} onChange={ e => change_this(e, setTaille) } key='S' />
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ annonce.taille == 'M' ? 'opt bg2 h ta-center bg3' : 'opt bg2 h ta-center' }>M</span>
                                        <p className='fz09'>30-60 cm</p>
                                        <input type='radio' value='M' name='taille' checked={ taille == 'M'} onChange={ e => change_this(e, setTaille) } key='M' />
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ annonce.taille == 'L' ? 'opt bg2 h ta-center bg3' : 'opt bg2 h ta-center' }>L</span>
                                        <p className='fz09'>60-90 cm</p>
                                        <input type='radio' value='L' name='taille' checked={ taille == 'L'} onChange={ e => change_this(e, setTaille) } key='L' />
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ annonce.taille == 'XL' ? 'opt bg2 h ta-center bg3' : 'opt bg2 h ta-center' }>XL</span>
                                        <p className='fz09'>90-130 cm</p>
                                        <input type='radio' value='XL' name='taille' checked={ taille == 'XL'} onChange={ e => change_this(e, setTaille) } key='XL' />
                                    </div>
                                    
                                </div>
                            </div>

                            <label className='mb0'>
                                <span>Conseils d’entretien ( optionnel ) : </span>
                                <textarea name="conseils" placeholder="ex : Parfaite pour sublimer votre salle de bain !" defaultValue={ annonce.conseils }></textarea>
                            </label>
                        </div>   

                    </div>

                    <div className="bg7 br8 p20 mt20">

                        <div className="col-9 m0a">
                            
                            <div className='label'>

                                <div className='col-3'>
                                    <label><span className='mb10 d-block'>Mode d'expédition</span></label>
                                    
                                    {

                                    mode_envoi == 'colis'

                                    &&

                                    <p className='fz07'>Favorise ta boite aux lettre et l’acheteur pourra aussi récupérer son colis en point relais. (À condition que ta plante soit d’accord)</p>

                                    }

                                </div>

                                <div className='col-65 flex jc-start m_col_4 m_mt_20'>

                                    <div className="flex col-3 mr20 m_flex" id='mode_envoi'>

                                        <div className='ta-center mr30 _choix'>
                                            <span className={ `opt bg2 h ta-center icon ${annonce.mode_envoi == 'enveloppe' ? 'bg3' : '' }` }>c</span>
                                            <p className='fz09'>Enveloppe</p>
                                            <input type='radio' value='enveloppe' name='mode_envoi' checked={ mode_envoi == 'enveloppe' } onChange={ change_mode_envoi }/>
                                        </div>

                                        <div className='ta-center mr30 _choix'>
                                            <span className={ `opt bg2 h ta-center icon ${annonce.mode_envoi == 'colis' ? 'bg3' : '' }` }>s</span>
                                            <p className='fz09'>Colis</p>
                                            <input type='radio' value='colis' name='mode_envoi' checked={ mode_envoi === "colis" } onChange={ change_mode_envoi }/>
                                        </div>

                                    </div>

                                    <div className="flex col-4 mr20 m_flex" id='type_envoi' style={{paddingTop: '7px'}}>

                                        <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'none' : 'block'}}>
                                            <span className={ `opt bg2 h ta-center mini ${type_envoi == 'poste' ? 'bg3' : '' }` }></span>
                                            <p className='fz09'>Bureau de poste</p>
                                            <input type='radio' value='poste' name='type_envoi' checked={ type_envoi == 'poste'} onChange={ e => change_this(e, setTypeEnvoi) }/>
                                        </div>

                                        <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'none' : 'block'}}>
                                            <span className={ `opt bg2 h ta-center mini ${type_envoi == 'boite' ? 'bg3' : '' }` }></span>
                                            <p className='fz09'>Boite aux lettres</p>
                                            <input type='radio' value='boite' name='type_envoi' checked={ type_envoi == 'boite'} onChange={ e => change_this(e, setTypeEnvoi) }/>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div> 

                        <div className="col-9 m0a">
                        
                            <div className='label'>
                                <label><span>Poids de l’enveloppe/colis</span></label>
                                <div className="flex col-65 jc-start fz09 m_col_10 m_flex m_f_wrap m_mt_20" id='poids_colis'>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'block' : 'none'}}>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '20' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 20 g</p>
                                        <input type='radio' value='20' name='poids_colis' checked={ poids_colis == '20'} onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'enveloppe' ? 'block' : 'none'}}>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '100' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 100 g</p>
                                        <input type='radio' value='100' name='poids_colis' checked={ poids_colis == '100'} onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '250' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 250 g</p>
                                        <input type='radio' value='250' name='poids_colis' checked={ poids_colis == '250'} onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '500' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 500 g</p>
                                        <input type='radio' value='500' name='poids_colis' checked={ poids_colis == '500'} onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'colis' ? 'block' : 'none'}}>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '750' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 750 g</p>
                                        <input type='radio' value='750' name='poids_colis' checked={ poids_colis == '750'} onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '1000' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 1 kg</p>
                                        <input type='radio' value='1000' name='poids_colis' checked={ poids_colis == '1000' } onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix'>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '2000' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 2 kg</p>
                                        <input type='radio' value='2000' name='poids_colis' checked={ poids_colis == '2000' } onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>

                                    <div className='ta-center mr30 _choix' style={{display: mode_envoi == 'colis' ? 'block' : 'none'}}>
                                        <span className={ `opt bg2 h ta-center mini ${annonce.poids_colis == '5000' ? 'bg3' : '' }` }></span>
                                        <p className='fz09'>Max 5 kg</p>
                                        <input type='radio' value='5000' name='poids_colis' checked={ poids_colis == '5000' } onChange={ e => change_this(e, setPoidsColis) }/>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>

                        <div className="col-9 m0a">

                            <label className='mb0'>

                                <div>
                                    <span>Poids exacte (g)</span>
                                    <p className='fz08'>Permet de simplifier l’achat en lot</p>
                                </div>

                                <input type="number" name="poids" placeholder="Ex : 120 g" required={true} defaultValue={ annonce.poids }/>
                            </label>

                        </div>

                    </div>

                    <div className="ta-end mt20">
                        <input type="submit" name="submit" value="Modifier" className="btn bg3 cl5 brc3 brc1solid" />
                    </div>
                </form>
            </div>

        </div>

            <Footer/>

        </div>   
    )

}

export default ModifierAnnonce