import { API_ROOT, API_SECRET, AVATAR_DEFAULT, AVATAR_ROOT } from '../constants/general';

class Func {

    static session(context, navigate, route = '/connexion', flash = false) {

        if((context.session !== true)){

            if(flash) this.setFlash("Connectez vous pour accéder à cette page", "error")

            navigate(route)

        }

    }

    static setFlash(message, type = "succes") {
             
        localStorage.setItem('message', message)
        localStorage.setItem('message_type', type)

    }

    static getFlash(setFlash) {
             
        if (localStorage.getItem('message') !== undefined && localStorage.getItem('message_type') !== undefined) {

            let message = localStorage.getItem('message')
            let message_type = localStorage.getItem('message_type')
            
            setFlash({"message" : message, "type" : message_type})

            localStorage.removeItem('message')
            localStorage.removeItem('message_type')

        }

    }

    static logout = (context, navigate = false) => {

        context.setSession(false)

        localStorage.removeItem('idU');
        localStorage.removeItem('app_token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('avatar');
        localStorage.removeItem('likes');

        if(navigate !== false) navigate('/')   

    }

    static saveSession(data, likes, setSession) {
             
        localStorage.setItem('idU', data.idU)
        localStorage.setItem('app_token', data.app_token)
        localStorage.setItem('name', data.name)
        localStorage.setItem('email', data.email)
        localStorage.setItem('avatar', data.avatar)
        localStorage.setItem('likes', likes)

        setSession(true)

    }

    static openMenu(){

        document.body.classList.toggle('mn')
    
    }

    static choixSelect(element){

        let radios = document.querySelectorAll(element)
    
        radios.forEach(element => {

            element.addEventListener('click', function(el){

                radios.forEach((elem) => elem.querySelector('span').classList.remove('bg3'))

                this.querySelector('span').classList.add('bg3')
                
                if(this.querySelector('input') !== null) this.querySelector('input').click()

            })

        })
      
    }

    static avatar(avatar){

        if(avatar == 0){

            return AVATAR_DEFAULT

        }else{

            return AVATAR_ROOT + avatar + '.jpg'
            
        }
    
    }

    static note(note){

        return(
            <div className="rating">
                { note == 0 && <span className='icon'>n</span> }
                { note >= 1 && <span className='icon'>p</span> }
                { note >= 2 ? <span className='icon'>p</span> : <span className='icon'>n</span> }
                { note >= 3 ? <span className='icon'>p</span> : <span className='icon'>n</span> }
                { note >= 4 ? <span className='icon'>p</span> : <span className='icon'>n</span> }
                { note >= 5 ? <span className='icon'>p</span> : <span className='icon'>n</span> }
            </div>
        )
    
    }

    static envoyeMessageBox(message){

        let messages_box = document.querySelector('#messages_box')

        let mon_message = document.createElement('div')
        mon_message.classList.add('message', 'bg9', 'p10', 'br8', 'col-8')
        mon_message.style.marginLeft = '20%'

        let cnt = document.createElement('div')
        cnt.classList.add('cnt')

        let p = document.createElement('div')
        p.innerText = message

        cnt.append(p)
        mon_message.append(cnt)

        messages_box.append(mon_message)

    }
    
}

export default Func