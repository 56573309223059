import { API_ROOT, API_SECRET } from '../constants/general'

class Pages {

    static get = (url) => {

        let data = fetch(API_ROOT + "pages/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                url: url
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data

    }

}

export default Pages