import { API_ROOT, API_SECRET } from '../constants/general';

export default class Paiements_Class {

    static cardRegistrations = () => {

        let data = fetch(API_ROOT + "paiements/card_registrations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static user_cards = () => {

        let data = fetch(API_ROOT + "paiements/user_cards", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static transactions = (type) => {

        let data = fetch(API_ROOT + "paiements/transactions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                type: type
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static deleteCard = (id) => {

        let data = fetch(API_ROOT + "paiements/delete_card", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static paiements = () => {

        let data = fetch(API_ROOT + "paiements/paiement_informations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static kyc = () => {

        let data = fetch(API_ROOT + "paiements/paiement_kyc", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static payout = () => {

        let data = fetch(API_ROOT + "paiements/payout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static addIban = (iban, name) => {

        let data = fetch(API_ROOT + "paiements/add_iban", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                iban,
                name
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static sendKyc = (firstname, lastname, type, file) => {

        let data = fetch(API_ROOT + "paiements/send_kyc", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                firstname,
                lastname,
                type,
                file
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}