import { API_ROOT, API_SECRET } from '../constants/general';

class Discussions {

    static envoyer_offre = (inputs, message) => {

        let data = fetch(API_ROOT + "discussions/envoyerOffre", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                annonce_id: inputs.annonce_id.value,
                offre: inputs.offre.value,
                message: message
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static envoyer_message = (discussion_id, message) => {

        let data = fetch(API_ROOT + "discussions/envoyerMessage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                discussion_id: discussion_id,
                message: message
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data

    }

    static repondre_message = (discussion_id, message_id, message_type) => {

        let data = fetch(API_ROOT + "discussions/repondreMessage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                discussion_id: discussion_id,
                message_id: message_id,
                message_type: message_type
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static mes_discussions = (options = {}) => {

        let data = fetch(API_ROOT + "discussions/mesDiscussions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                options: options
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static discussion = (id) => {

        let data = fetch(API_ROOT + "discussions/discussion", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}

export default Discussions