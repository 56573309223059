import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

function BoiteOutils() {

    useEffect(() => {

        document.title = "Boite à outils - " + APP_NAME

        document.body.classList.remove('mn')

    }, [])


    return (

        <div>

            <Header />

            <div>

                <HeaderBox />

                <div className='content m_p10'>

                    <div className='boite-box col-4 ta-center'>

                        <img src='/image/autres/image-boite-a-outils-sisters-green.webp' />

                        <div className='mt70'>

                            <p>N’oublie pas de télécharger une des étiquettes</p>

                            <p className='fw5 fz12 mt20 mb20'>“ PLANTE VIVANTE ”</p>

                            <p className='mb40'>et la coller sur ton colis/enveloppe.</p>


                            <a href='/image/autres/etiquette_plante_vivante.pdf' target='_blank'>Étiquette N° 1 à télécharger</a>
                            <a href='/image/autres/etiquette_plante_vivante_2.pdf' target='_blank'>Étiquette N° 2 à télécharger</a>

                        </div>

                    </div>
                    
                </div>

            </div>

            <Footer />

        </div>

    )

}

export default BoiteOutils