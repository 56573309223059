// Gla Slider

let gla_slider = class {

    parent = null
    childs = []
    slider = null
    position = 0
    position_slides = 0 
    childs_nbr = 0

    screen_size = 'min_600'

    timer = null

    option = {
        childs: 5,
        items: ".gla-item",
        margin: 10,
        btn_class: "bgt",
        next: "",
        previos: "",
        speed: 5
    }

    constructor(element, option) {

        this.option = { ...this.option, ...option }

        this.parent = document.querySelector(element)

        this.size()

        this.init()

    }

    init() {

        this.parent.classList.add("gla_slider")
        this.parent.classList.add(this.option.slider_type)

        let parent_width = this.parent.innerWidth || this.parent.clientWidth

        this.childs = this.parent.querySelectorAll(this.option.items)

        this.childs_nbr = this.childs.length

        this.child_width = parent_width / this.option.childs

        if (this.screen_size == 'min_960') {

            this.option.childs = Math.ceil(this.option.childs / 2)

            this.child_width = parent_width / this.option.childs

        } else if (this.screen_size == 'min_600') {

            this.option.childs = Math.ceil(this.option.childs / 3)

            this.child_width = parent_width / this.option.childs

        } else if (this.screen_size == 'min_400') {

            this.option.childs = 1

            this.child_width = parent_width

        }

        this.position_slides = this.childs.length - this.option.childs

        this.childs.forEach(function (elem) {

            elem.style.margin = '0' + this.option.margin + 'px'
            elem.style.width = this.child_width + 'px'
            elem.style.minWidth = 'unset'

        }, this)

        this._slider(this.parent.innerHTML)

        this.parent.innerHTML = ''

        this.parent.appendChild(this.slider)

        this.btns()

        this.timeout()

    }

    _slider(HTML) {

        this.slider = document.createElement('div')
        this.slider.classList.add('gla_slider_slider')

        let slider_width = this.child_width * this.childs_nbr

        this.slider.style.width = slider_width + 'px'

        this.slider.innerHTML = HTML

    }

    btns() {

        if (this.childs_nbr <= this.option.childs) return false

        let next_btn = document.createElement('span')
        next_btn.className = 'icon slider_btn next_btn'
        next_btn.classList.add(this.option.btn_class)
        next_btn.innerText = this.option.next

        next_btn.addEventListener('click', this.next.bind(this))

        let previos_btn = document.createElement('span')
        previos_btn.className = 'icon slider_btn previos_btn'
        previos_btn.classList.add(this.option.btn_class)
        previos_btn.innerText = this.option.previos

        previos_btn.addEventListener('click', this.previos.bind(this))

        this.parent.appendChild(next_btn)
        this.parent.appendChild(previos_btn)

    }

    next() {

        if (this.position < this.position_slides) {

            this.position = this.position + 1

        } else {

            this.position = 0

        }

        this.slider.style.transform = "translateX(-" + this.child_width * this.position + "px)"

        this.timeout()
    }

    previos() {

        if (this.position == 0) {

            this.position = this.position_slides

        } else {

            this.position = this.position - 1

        }

        this.slider.style.transform = "translateX(-" + this.child_width * this.position + "px)"

        this.timeout()

    }

    timeout() {

        clearTimeout(this.timer)

        this.timer = setInterval(this.next.bind(this), this.option.speed * 1000);

    }

    // Media query

    size() {

        if (window.matchMedia("(min-width: 960px)").matches) {
            
            this.screen_size = 'max_960'

        } else if (window.matchMedia("(min-width: 600px)").matches) {

            this.screen_size = 'min_960'

        }else if (window.matchMedia("(min-width: 400px)").matches) {
            
            this.screen_size = 'min_600'

        } else {
            
            this.screen_size = 'min_400'
        }

    }

}

export default gla_slider