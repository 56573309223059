import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../components/imports'

import { APP_NAME, PHOTOS_ROOT, WEBROOT } from '../constants/general'

import { Context } from '../class/Context'

import Func from '../class/Func'

import Paiements_Class from '../class/Paiements_Class'

import Annonces from '../class/Annonces'

import User from '../class/User'

function Commander() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [flash, setFlash] = useState({})

    const [annonce, setAnnonce] = useState(false)
    const [offre, setOffre] = useState(false)

    const [pointsRelais, setPointsRelais] = useState(false)

    const [typeLiv, setTypeLiv] = useState("dom")

    const [prix, setPrix] = useState(0)

    const [cartes, setCartes] = useState(false)

    const [get_params] = useSearchParams()

    const [infos, setInfos] = useState(false)

    const [nbrAchat, setNbrAchat] = useState(1)

    const [livraisonPrix, setLivraisonPrix] = useState('0.00')

    const [livraison, setLivraison] = useState('0.00')
    const [livraisonPr, setLivraisonPr] = useState('0.00')

    const [valide, setValide] = useState(false)

    const myInfos = async () => {

        let results = await User.session(true)

        if(results.success){

            setInfos(results.data)

        }

    }

    const getCartes = async () => {

        let results = await Paiements_Class.user_cards()

        if(results.success){

            setCartes(results.data)

        }else{

            setCartes(results.message)

        }

    }

    const getAnnonce = async (annonce_id, offre_id) => {

        let results = await Annonces.annoncesByIdCommander(annonce_id)

        if(results.success){

            setAnnonce(results)

            setPrix(results.data.prix)

            setNbrAchat(results.nbr_achat)

            if(offre_id !== 0) getOffre(annonce_id, offre_id)

            setLivraisonPrix(results.data.prix_livraison)
            setLivraison(results.data.prix_livraison)
            setLivraisonPr(results.data.prix_livraison_pr)

            setValide(true)

            // livraison_Func(annonce_id)
    
        }else{
    
            navigate('/404')
    
        }
    
    }

    const getOffre = async (annonce_id, offre_id) => {

        let results = await Annonces.offreById(annonce_id, offre_id)

        if(results.success){
    
            setOffre(results.offre)

            let prix = JSON.parse(results.offre.opt)

            setPrix(prix.prix)
    
        }else{
    
            navigate('/404')
    
        }
    
    }

    const livraison_Func = async (annonce_id) => {

        let results = await Annonces.livraison(annonce_id)
        
        if(results.success){
    
            setLivraisonPrix(results.prix)
            setLivraison(results.prix)
            setLivraisonPr(results.prix_pr)

            setValide(true)
    
        }else{
    
            setFlash({message: results.message, type: "error"})

            setValide(false)
    
        }
    
    }

    const changeLivraisonType = async (prix, type) => {

        setLivraisonPrix(prix)

        setTypeLiv('dom')

        if(type == 'relais'){

            setTypeLiv('relais')

            let results = await Annonces.getPointsRelais()

            if(results.errorCode == 0){

                if(results.listePointRetraitAcheminement.length > 0){

                    setPointsRelais(results.listePointRetraitAcheminement)

                }else{

                    setPointsRelais("Acun point relais trouvé, veuillez vérifier votre adresse.")

                }
        
        
            }else{
        
                setFlash({message: "Impossible d'afficher les points relais.", type: "error"})
        
            }

        }

    }

    const payer = async (e) => {

        e.preventDefault()

        let inputs = e.target

        if(inputs.use_card == undefined){

            setFlash({message: "Tu dois ajouter une carte de paiement", type: "error"})

            return false

        }

        if(typeLiv == 'relais' && inputs.point_retrait == undefined && inputs.point_retrait.value == undefined){

            setFlash({message: "Tu dois choisir un poit relais pour la livraison", type: "error"})

            return false

        }

        if(inputs.use_card.value !== "" && inputs.livraison.value !== "" && valide && livraison > 0 && annonce){

            let offre_id = offre ? offre.i : 0

            let points_relais = (typeLiv == 'relais') ? inputs.point_retrait.value : 0

            setValide(false)

            let results = await Annonces.payer(inputs.use_card.value, annonce.data.idA, offre_id, inputs.livraison.value, points_relais)

            setValide(true)
    
            if(results.success){
        
                navigate('/mon-profile/discussion/' + results.discussion_id)

                setFlash({message: "Pay", type: "succes"})
        
            }else{
        
                setFlash({message: results.message, type: "error"})
        
            }

        }else{

            setFlash({message: "Veuillez ajouter selectionner votre moyen de paiement pour continuer", type: "error"})

        }
    
    }

    useEffect(() => {

        let annonce_id = get_params.get("annonce")
        let offre_id = get_params.get("offre")

        if(annonce_id == null) annonce_id = 0
        if(offre_id == null) offre_id = 0

        window.scrollTo(0, 0)

        Func.session(context, navigate, '/connexion', true)

        Func.getFlash(setFlash)

        myInfos()

        getAnnonce(annonce_id, offre_id)

        getCartes()

        document.title = "Commander - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

                <form method='POST' onSubmit={ (e) => payer(e) } className='c content flex ai-start jc-center'>

                    <div className="col-5 pos-r">

                        <div className="paiements m_p10 m_mt_10">

                            <div className="bg6 p20 br10 mb20">

                                <h2 className='fw5 mb20 d-block'>Détails de la commande</h2>

                                {

                                    annonce

                                    ?

                                    <div className='flex m_flex ai-center'>

                                        <div className="flex m_flex ai-center">
                                            <img src={ PHOTOS_ROOT + 'small/'+ annonce.photos[0].name +'.jpg'} className='br10 mr20 w_h_60' style={{width: '80px', height: '80px', objectFit: 'cover'}}/>
                                            <div>
                                                <p>{ annonce.data.titre } <span className="h bg3 opt">{ annonce.data.taille }</span></p>
                                                {/* <p className='mb5 fz08'>Poids : { annonce.data.poids_colis }g</p> */}
                                            </div>
                                        </div>

                                        <p className='fz12 fw7 m_col_3 ta-end'>{ prix } €</p>

                                    </div>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                }

                            </div>

                            <div className="bg6 p20 br10 mb20">
                                <h2 className='fw5 mb30 d-block'>Adresse de livraison</h2>

                                {

                                    infos

                                    ?

                                    <div>
                                        <div>
                                            <span className='d-block mb5'>{ infos.name }</span>

                                            {

                                                infos.ville

                                                ?

                                                    <>
                                                        <span className='d-block mb10'>{ infos.rue }, { infos.ville }</span>
                                                        <span className='d-block fw7'>{ infos.postal } { infos.ville }</span>
                                                    </>

                                                :

                                                    <p className='fz09 error'>Adresse manquante</p>

                                            }
                                            
                                        </div>

                                        {

                                            infos.ville

                                            ?

                                                <Link to={'/mon-profile/parametres#parametres'} className='btn bg4 cl5 mt20'>Modifier</Link>


                                            :

                                                <Link to={'/mon-profile/parametres#parametres'} className='btn bg4 cl5 mt20'>Ajouter</Link>

                                        }

                                    </div>

                                    :

                                    <div className='mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                }

                            </div>

                            <div className="bg6 p20 br10 mb20">
                                <h2 className='fw5 mb20 d-block'>Livraison</h2>

                                {

                                    annonce

                                    &&

                                    annonce.data.mode_envoi == 'enveloppe'

                                    ?

                                        <></>

                                    :

                                        <img src={ WEBROOT + "image/autres/colissimo.png" } className="col-2 m_col_4 mb10"/>

                                }

                                {

                                    annonce

                                    ?

                                        annonce.data.mode_envoi == 'enveloppe'

                                        ?

                                            <label className='d-block mb10'>
                                                <input type="radio" name='livraison' className='mr10' value="domicile" defaultChecked={true} />
                                                Domicile (Lettre suivie) <strong>{ livraison } €</strong>
                                            </label>

                                        :

                                            <>

                                                <label className='d-block mb10'>
                                                    <input type="radio" name='livraison' className='mr10' value="domicile" defaultChecked={true} onChange={ e => changeLivraisonType(livraison, 'dom') } />
                                                    Domicile <strong>{ livraison } €</strong>
                                                </label>

                                                <label>
                                                    <input type="radio" name='livraison' className='mr10' value="point-relais" onChange={ e => changeLivraisonType(livraisonPr, 'relais') }/>
                                                    Point relais <strong>{ livraisonPr } €</strong>
                                                </label>

                                                {
                                                    
                                                    typeLiv == 'relais'

                                                    ?

                                                        pointsRelais

                                                        ?

                                                            typeof pointsRelais == "string"

                                                                ?

                                                                    <p className='fz09'>{ pointsRelais }</p>

                                                                :

                                                                <>

                                                                    <h2 className='fw5 mb20 d-block mt40'>Points de retrait disponibles</h2>

                                                                    {

                                                                        pointsRelais.map(pr => 
                                                                    
                                                                            <div className='mb10' key={pr.identifiant}>
                                                                                <label className='flex jc-start'>
                                                                                
                                                                                    <input type="radio" name='point_retrait' value={ pr.identifiant } className='mr10' required/>
                                                                                    
                                                                                    <div>
                                                                                        <p className='fw5'>{ pr.nom } ({ pr.distanceEnMetre / 1000 } Km)</p>
                                                                                        <span className='fz08 d-block '>{ pr.adresse1 }, { pr.codePostal } { pr.localite }</span>
                                                                                        { pr.adresse2 != "" && <span className='fz08 d-block'>{ pr.adresse2 }</span> }
                                                                                        { pr.adresse3 != "" && <span className='fz08 d-block'>{ pr.adresse3 }</span> }
                                                                                    </div>
                                                                            
                                                                                </label>

                                                                            </div>                                                                    
                                                                        
                                                                        )

                                                                    }

                                                                </>

                                                        :

                                                            <div className='mt50 mb50'>
                                                                <div className="lds-dual-ring col"></div>
                                                            </div>

                                                    :

                                                    <></>

                                                }

                                            </>
                                    
                                    :

                                    <div className='mt20 mb20'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                }


                            </div>

                            <div className="bg6 p20 br10 mb20 m_mb_0">

                                <h2 className='fw5 mb30 d-block'>Options de paiement</h2>

                                <div>

                                        {

                                            cartes

                                            ?

                                                typeof cartes == "string"

                                                ?

                                                    <p className='fz09'>{ cartes }</p>

                                                :

                                                cartes.map(carte =>

                                                    carte.Active

                                                    &&

                                                    <label className="flex m_flex ai-center jc-start mb20" key={carte.Id}>
                                                        
                                                        <input type="radio" className='mr10' name='use_card' value={carte.Id} required={true} />

                                                        <div>
                                                            <span className='d-block'>Carte { carte.CardProvider } : { carte.Alias }</span>
                                                            {/* <span>Expiration { carte.ExpirationDate.substring(0,2) + '/20' + carte.ExpirationDate.substring(2,4) }</span> */}
                                                        </div>

                                                    </label>
                                                
                                                )

                                            :

                                            <div className='mt50 mb50'>
                                                <div className="lds-dual-ring col"></div>
                                            </div>

                                        }

                                        <div>
                                            <Link to={'/mon-profile/paiements/ajouter_carte'} className='mt20 btn bg4 cl1'>Ajouter</Link>
                                        </div>
                                        
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='col-3 m_p10'>
                        
                        <div className='ml20 bg7 br10 p20 m_ml_0'>

                            <h3 className='mb30'>Résumé de la commande</h3>

                            {

                                annonce

                                ?

                                <div className='fz08'>

                                    <div className='flex m_flex mb5'>
                                        <strong>Commande</strong>
                                        <p>{ prix } €</p>
                                    </div>

                                    <div className='flex m_flex mb5'>
                                        <strong>Frais de service</strong>
                                        <p>{ ((parseFloat(prix) * 0.05) + 0.98).toFixed(2) } €</p>
                                    </div>

                                    <div className='flex m_flex mb5'>
                                        <strong>Frais de port</strong>
                                        <p>{ livraisonPrix } €</p>
                                    </div>

                                    <div className='flex m_flex mb5'>
                                        <strong>Total</strong>
                                        <strong>{ (parseFloat(prix) + parseFloat(livraisonPrix) + (parseFloat(prix) * 0.05) + 0.98).toFixed(2) } €</strong>
                                    </div>

                                    {

                                        nbrAchat == 0

                                        &&

                                        <div className='flex m_flex mb5 jc-start mt20'>
                                            <input type='checkbox' name='cgu' className='mr5' required={true} />
                                            <p className='fw5 fz09'>J'accepte les <Link to={'https://www.laposte.fr/conditions-generales-de-vente'} target='_blank' className='cl4'>CGV</Link>, <Link to={'https://www.laposte.fr/conditions-contractuelles'} target='_blank' className='cl4'>CSV</Link> Colissimo et Courrier</p>
                                        </div>

                                    }

                                </div>

                                :

                                <div className='flex jc-center mt50 mb50'>
                                    <div className="lds-dual-ring col"></div>
                                </div>

                            }

                            { flash !== '' && <p className={ flash.type + " fz08 mb0"}>{ flash.message }</p>}

                            {
                                
                                valide
                                
                                ?
                                
                                    <input type="submit" className='btn bg4 col-10 cl1 ta-center mt20 brc1solid brc4' value="Payer" />

                                :

                                    <div className='flex jc-center mt10 mb30'>
                                        <div className="lds-dual-ring"></div>
                                    </div>

                            }
                            
                            <p className='fz07 d-block ta-center mt5'>Paiement crypté et sécurisé</p>
                            
                            <div className='flex m_flex jc-center mt20'>
                                <img src="/image/mangopay_powered_by.png" className='col-3 m_col_25'/>
                            </div>

                        </div>

                    </div>

                </form>

            </div>

            <Footer/>

        </div>

    )

}

export default Commander