import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Navigate, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import User from '../../class/User'

function Ajouter_avis() {

    const { id } = useParams()

    const navigate = useNavigate();

    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [loading, setLoading] = useState(0)

    const publier = async (e) =>{

        e.preventDefault()
    
        let inputs = e.target

        setLoading(1)
    
        const results = await User.ajouterAvis(id, inputs)
    
        if(results.success){

            setFlash({type: 'succes', message: results.message})

            setLoading(2)
    
        }else{

            setFlash({type: 'error', message: results.message})

            setLoading(0)
    
        }
    
    }

    useEffect(() => {
        
        Func.session(context, navigate)
        
        if(id == null) navigate('/404')

        document.title = "Ajouter un avis - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div>

                    <div className="c pos-r">

                        <div className="settings">

                            {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

                            {

                                loading == 0

                                &&

                                <div className="bg6 p30 br10 mb20">
                                    
                                    <form method='POST' className='gla-form' onSubmit={(e) => publier(e)}>

                                        <h3 className='mb30 fw3'>Je donne mon évaluation</h3>

                                        <label>
                                            <span>Note (Nombre d'étoiles)</span>

                                            <select name='note' required={true}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5" selected>5</option>
                                            </select>
                                        </label>

                                        <label>
                                            <span>Avis</span>
                                            <input name='avis' placeholder='Ex: Colis bien fait et vendeur professionnel' required={true}/>
                                        </label>

                                        <div className="ta-end mt20">
                                            <input type="submit" name="submit" value="J'envoie mon avis" className="btn cl1 bg4 brc4 brc2solid fw5" />
                                        </div>

                                    </form>
                                </div>

                            }

                            {

                                loading == 1

                                &&

                                <div className="bg6 p30 br10 mb20">
                                    
                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                </div>

                            }

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default Ajouter_avis